import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { getMeta } from "redux/meta"
import { white } from "styles/colors"
import { TOPMOST } from "styles/zIndex"

const { REACT_APP_ENV, REACT_APP_VERSION } = process.env

const Wrapper = styled.div`
	z-index: ${TOPMOST + 1};
	padding: 1px 2px;
	color: ${({ color }) => color};
	font-size: 0.6em;
	font-weight: bold;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: var(--ion-safe-area-top, 0);
`

export const VersionDisplay = ({ color, meta }) => {
	const { browser, os } = meta
	return (
		<Wrapper color={color}>
			{REACT_APP_ENV}; {REACT_APP_VERSION}; {browser}; {os}
		</Wrapper>
	)
}

VersionDisplay.defaultProps = {
	color: white,
	meta: {},
}

const mapStateToProps = (state) => ({
	meta: getMeta(state),
})

export default connect(mapStateToProps)(VersionDisplay)
