import React, { useState } from 'react'
import {
	IonIcon,
	IonItem,
	IonItemOption,
	IonItemOptions,
	IonItemSliding,
	IonList,
	IonListHeader
} from '@ionic/react'
import { close } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { llmError, llmSuccess, llmWarning, medium, white } from 'styles/colors'
import Dialog from 'components/Form/Dialog'
import QRCodeDisplay from 'components/QRCodeDisplay'
import { findFormat, formatDisplay, noop } from 'utils/helpers'
import { parseCrossDockQRCodeData } from 'utils/qrcode'
import storage, { CROSSDOCKS } from 'utils/storage'
import { ListItem, Status, Text, SubText, highlightMatch } from '../Summary'

const PARCEL_STATUS = {
	REVIEWING: 'PENDING',
	APPROVED: 'CROSSDOCKED',
	REJECTED: 'NOT_CROSSDOCKED'
}
const COLOR_MAP = {
	REVIEWING: llmWarning,
	APPROVED: llmSuccess,
	REJECTED: llmError
}

const { REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2 } = process.env
// for phase 1, default status is CROSSDOCKED:
const DEFAULT_STATUS =
	REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2 === 'true'
		? 'REVIEWING'
		: 'CROSSDOCKED'

const SectionItem = styled(IonItem)`
	--padding-start: 0;
	--inner-padding-end: 0;
	ion-list {
		width: 100%;
	}
	ion-item {
		--min-height: 36px;
	}
`

const SectionList = styled(IonList)`
	background-color: ${white};
`

const Title = styled.span`
	font-weight: bold;
`

const SubTitle = styled.div`
	font-size: 14px;
	font-weight: normal;
	color: ${medium};
`

// main SummarySection component for cross-docking items
const SummarySection = ({
	data,
	submittedAt,
	courier,
	onItemClick,
	onRemove,
	displayFormats,
	searchString,
	clientNames,
	squared
}) => {
	const { i18n, t } = useTranslation()
	const [isQrOpen, setIsQrOpen] = useState(false)
	const [isDialogOpen, setIsDialogOpen] = useState(false)

	const { DOCKID, DOCKSTATUS, BARCODE } = data
	const cdQRCodeData = parseCrossDockQRCodeData(BARCODE)
	cdQRCodeData.forEach(i => {
		i[0].format = findFormat(
			displayFormats
				.filter(f => f.clientId === i[0].clientId)
				.map(f => f.format),
			i[0].value
		)
	})

	const handleRemove = () => {
		const cds = JSON.parse(storage.getItem(CROSSDOCKS)) || {}
		if (cds.list) {
			cds.list = cds.list.filter(cd => cd.data.DOCKID !== DOCKID)
			cds.updatedAt = new Date().toISOString()
			storage.setItem(CROSSDOCKS, JSON.stringify(cds))
			onRemove()
		}
	}

	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit'
	}
	const datetime = new Date(submittedAt).toLocaleString(
		i18n.language,
		options
	)

	return (
		<>
			{/* sliding to remove cross-docking items is disabled for now */}
			<IonItemSliding disabled>
				<SectionItem lines="none">
					<SectionList>
						<IonListHeader>
							<Title>
								{t('Summary.heading_cross_docked', {
									datetime,
									interpolation: { escapeValue: false }
								})}
								{courier && (
									<SubTitle>
										{t('Summary.label_courier_info', {
											...courier
										})}
									</SubTitle>
								)}
							</Title>
							<img
								src="assets/icon/qrcode.svg"
								alt="QR Code"
								width="24"
								style={{ width: '24px', margin: '0 16px' }}
								onClick={() => setIsQrOpen(true)}
							/>
						</IonListHeader>
						{cdQRCodeData.map((br, i) => (
							<ListItem
								button
								onClick={() => onItemClick(br[0].value)}
								key={br[0].value}
								lines="none"
							>
								<Text>
									{searchString
										? highlightMatch(
												br[0].value,
												searchString
										  )
										: formatDisplay(
												br[0].value,
												br[0].format
										  )}
									<SubText>{`[${
										clientNames[br[0].clientId]
									}]`}</SubText>
								</Text>
								{i === 0 && (
									<Status
										active
										color={
											COLOR_MAP[
												DOCKSTATUS || DEFAULT_STATUS
											]
										}
										squared={squared}
									>
										{t(
											`PARCEL_STATUS.${
												PARCEL_STATUS[
													DOCKSTATUS || DEFAULT_STATUS
												]
											}`
										)}
									</Status>
								)}
							</ListItem>
						))}
					</SectionList>
				</SectionItem>
				<IonItemOptions side="end">
					<IonItemOption
						color="danger"
						onClick={() => setIsDialogOpen(true)}
					>
						<IonIcon size="large" icon={close}></IonIcon>
					</IonItemOption>
				</IonItemOptions>
			</IonItemSliding>
			<QRCodeDisplay
				isOpen={isQrOpen}
				backdropDismiss
				onClose={() => setIsQrOpen(false)}
				data={data}
				headingLabel={`${t('Form.label_only_number_item')}: `}
				headingValue={cdQRCodeData.length}
				id={data.DOCKID}
			/>
			<Dialog
				isOpen={isDialogOpen}
				header={t('Remove these cross-docked items?')}
				message={cdQRCodeData
					.map(d => formatDisplay(d[0].value, d[0].format))
					.join('<br>')}
				onNo={() => setIsDialogOpen(false)}
				onYes={handleRemove}
				onDismiss={() => setIsDialogOpen(false)}
				hasOptions
			/>
		</>
	)
}

SummarySection.defaultProps = {
	onItemClick: noop,
	displayFormats: [],
	searchString: '',
	squared: false
}

export default React.memo(SummarySection)
