import React from 'react'
import { IonAlert } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { noop } from 'utils/helpers'

const Dialog = ({
	isOpen,
	header,
	message,
	buttonNoText,
	buttonYesText,
	buttonOkText,
	onNo,
	onYes,
	onOk,
	onDismiss,
	hasOptions,
	dialogClass,
}) => {
	const { t } = useTranslation()
	return (
		<IonAlert
			animated={false}
			isOpen={isOpen}
			onDidDismiss={onDismiss}
			header={t(header)}
			message={message}
			cssClass={dialogClass}
			buttons={
				hasOptions
					? [
						{
							text: t(buttonNoText),
							handler: onNo,
							cssClass: 'no-button',
						},
						{
							text: t(buttonYesText),
							handler: onYes,
							cssClass: 'yes-button',
						},
					]
					: [
						{
							text: t(buttonOkText),
							handler: onOk,
							cssClass: 'ok-button',
						},
					]
			}
		/>
	)
}

Dialog.defaultProps = {
	isOpen: false,
	// TODO: align header and message with button texts
	header: '',
	message: '',
	buttonNoText: 'Common.button_no',
	buttonYesText: 'Common.button_yes',
	buttonOkText: 'Common.button_ok',
	onNo: noop,
	onYes: noop,
	onOk: noop,
	onDismiss: noop,
	hasOptions: true,
	dialogClass: 'default-dialog',
}

export default Dialog
