import React from "react"
import {
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCol,
	IonGrid,
	IonRow,
} from "@ionic/react"
import styled from "styled-components"
import { white } from "styles/colors"
import { getTranslationValue, noop } from "utils/helpers"

const StyledCard = styled(IonCard)`
	cursor: pointer;
	background-color: ${white};
`

const ImgContainer = styled.div`
	position: relative;
	width: 100%;
	background-color: #f3f3f3;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	img {
		position: absolute;
		top: 1em;
		right: 1em;
		bottom: 1em;
		left: 1em;
		width: calc(100% - 2em);
		height: calc(100% - 2em);
		object-fit: contain;
		object-position: center;
	}
`

const ButtonGrid = ({ list, onClick }) => {
	const handleClick = (id) => {
		onClick(id)
	}

	const renderCol = (item) => {
		const { id, translations } = item
		const clientName = getTranslationValue(translations, id, "name")
		return (
			// IonGrid is based on a 12 column layout:
			<IonCol key={id} size="6">
				<StyledCard
					id={`${id}-client-card`}
					onClick={() => handleClick(id)}
					className="gtm-btn-client-select-item"
				>
					<ImgContainer>
						<img
							src={getTranslationValue(
								translations,
								"assets/icon/company-placeholder.png",
								"logo"
							)}
							alt={`${clientName} Logo`}
						/>
					</ImgContainer>
					<IonCardHeader>
						<IonCardSubtitle>{clientName}</IonCardSubtitle>
					</IonCardHeader>
				</StyledCard>
			</IonCol>
		)
	}

	const renderRows = () => {
		const rows = []
		let cols = []
		list.forEach((item, i) => {
			if (i % 2 === 0) {
				cols = [renderCol(item, i)]
				if (i === list.length - 1) {
					rows.push(<IonRow key={i}>{cols}</IonRow>)
				}
			} else {
				cols.push(renderCol(item, i))
				rows.push(<IonRow key={i}>{cols}</IonRow>)
			}
		})
		return rows
	}

	return <IonGrid>{renderRows()}</IonGrid>
}

ButtonGrid.defaultProps = {
	list: [],
	onClick: noop,
}

export default ButtonGrid
