import React from 'react'
import styled from 'styled-components'
import { danger } from 'styles/colors'
import { monospaceFont } from 'styles/fonts'

const Asterisk = styled.span`
	color: ${danger};
`

const Value = styled.span`
	font-family: ${monospaceFont};
	font-size: 22px;
	font-weight: bold;
`

// form field heading
const Heading = ({ label, value, isRequired }) => {
	return (
		<h5>
			<span>{label}</span>
			<Value>{value}</Value>
			{isRequired && <Asterisk>*</Asterisk>}
		</h5>
	)
}

Heading.defaultProps = {
	label: '',
	value: '',
	isRequired: false,
}

export default Heading
