import React from 'react'
import { IonIcon, IonModal } from '@ionic/react'
import styled from 'styled-components'
import { danger, white } from 'styles/colors'

const Container = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: ${white};
	background-color: ${danger};
`

const Modal = ({ isOpen, message, requestId }) => {
	return (
		<IonModal isOpen={isOpen} backdropDismiss={false} animated={false}>
			<Container>
				<div>
					<div>
						<IonIcon icon="close-circle" size="large" />
					</div>
					<p>
						<b>{message}</b>
					</p>
					{requestId && <p>{`Request ID: ${requestId}`}</p>}
				</div>
			</Container>
		</IonModal>
	)
}

Modal.defaultProps = {
	isOpen: false,
	message: '',
	requestId: '',
}

export default Modal
