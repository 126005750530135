import React from 'react'
import { IonCard, IonCardContent, IonSpinner } from '@ionic/react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { llmPrimary, white } from 'styles/colors'
import { noop } from 'utils/helpers'

const StyledCard = styled(IonCard)`
	margin: 2em 0 1em;
	padding: 1em;
	background-color: ${white};

	img {
		max-width: 80px;
		margin: auto;
	}
	div,
	p {
		text-align: center;
	}
`

const ShiftCard = ({
	image,
	text,
	details,
	buttonAction,
	buttonLabel,
	buttonClass,
	loading,
	disabled,
	cardId
}) => {
	const { t } = useTranslation()
	return (
		<StyledCard id={cardId}>
			<img src={`assets/icon/${image}`} alt="icon" />
			<IonCardContent>
				<div>{text}</div>
				{/* just styling: */}
				{(!!details.length || buttonAction !== noop) && <br />}
				{buttonAction !== noop && (
					<p>
						<Button
							theme={{ bgColor: llmPrimary }}
							onClick={buttonAction}
							disabled={loading || disabled}
							className={buttonClass}
						>
							{/* TODO: confirm button's text for disabled state after cutoff */}
							{loading ? (
								<IonSpinner />
							) : (
								t(
									disabled
										? 'Shift.button_over_cutoff_time'
										: buttonLabel
								)
							)}
						</Button>
					</p>
				)}
				{!!details.length &&
					details.map(({ label, value }, i) => (
						<p key={i}>
							<b>{t(label)}: </b>
							{value}
						</p>
					))}
			</IonCardContent>
		</StyledCard>
	)
}

ShiftCard.defaultProps = {
	image: 'courierbox.png',
	text: null, // node
	buttonAction: noop,
	buttonLabel: '',
	buttonClass: '',
	details: [], // arrayOf(shape({ label: string, value: node }))
	loading: false,
	disabled: false,
	cardId: 'shift-card'
}

export default ShiftCard
