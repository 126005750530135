import React from 'react'
import styled from 'styled-components'
import {
	IonReorder,
	IonItemSliding,
	IonItemOptions,
	IonItemOption,
	IonItem,
	IonIcon
} from '@ionic/react'
import storage, { LOCATION, LOCATIONS } from 'utils/storage'
import { useTranslation } from 'react-i18next'
import { dark, llmWarning, white } from 'styles/colors'
import { close } from 'ionicons/icons'
import { getShortenDeliveryStageType } from '../../utils/helpers'
import { COLOR_MAP } from 'utils/constants'

const ItemContainer = styled(IonItem)`
	display: block;
	font-size: 16px;
	border: 1px solid #ccc;
	border-bottom: 0;
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
		0 1px 5px 0 rgb(0 0 0 / 12%);
	--padding-start: 0;
	--padding-end: 0;
	--inner-padding-start: 0px;
	--inner-padding-end: 0px;
`
const Wrapper = styled.div`
	width: 100%;
	letter-spacing: 0.5px;
`
const ItemHeader = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	background-color: #ddd;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	gap: 4px;
	span {
		height: 22px;
		align-items: center;
		justify-content: center;
		display: inline-flex;
	}
`
const StageTypeContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	& > span {
		color: ${white};
		padding: 0 8px;
		background-color: ${({ stage }) =>
			stage === 'FIRST_MILE' ? 'rgb(224,102,102)' : 'rgb(106, 168, 79)'};
	}
`
export const Status = styled.span`
	display: inline-flex;
	min-width: 9em;
	justify-content: center;
	font-size: 12px;
	color: ${({ active, color }) => (active ? white : color)};
	font-weight: bold;
	background-color: ${({ active, color }) => (active ? color : white)};
`
const ParcelIdContainer = styled.div`
	width: 100%;
	padding: 4px 0;
	color: ${dark};
`
const ItemContent = styled.div`
	width: 100%;
	padding: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
`
const Address = styled.div`
	span {
		font-weight: bold;
		margin-right: 4px;
	}
	line-height: 1.4;
	word-break: break-all;
`
const Contacts = styled.div`
	padding: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #ddd;
	img {
		width: 20px;
		margin-right: 8px;
	}
`

const ClientContainer = styled.div`
	display: inline-flex;
	align-items: center;
	flex-wrap: nowrap;
`
const PhoneContainer = styled.a`
	display: inline-flex;
	align-items: center;
	flex-wrap: nowrap;
	color: ${dark};
	text-decoration: none;
`
const Icon = styled.span`
	color: ${llmWarning};
`
const OfflineIcon = styled.img`
	&&& {
		width: 20px;
	}
`
const Extras = styled.div`
	border-top: 1px solid #ccc;
	padding: 8px;
	width: 100%;
	text-align: right;
	font-weight: bold;
`
const StyledIonReorder = styled(IonReorder)`
	margin-right: 0;
`

const ParcelRecordItem = ({
	parcel,
	handleEvents,
	showPendingUpload,
	showRetryUpload
}) => {
	const { t } = useTranslation()
	const getPhoneNumber = () => {
		const addresses = parcel.address ? [...parcel.address] : []
		const locationInfoArray = JSON.parse(storage.getItem(LOCATIONS)) || []
		const locationInfo = locationInfoArray.filter(
			location => location.id === storage.getItem(LOCATION)
		)
		const phoneFormats =
			locationInfo[0] && locationInfo[0].phoneFormats.templateIntl
		const phoneArray = addresses.map((addr, i) => {
			const contactPhone =
				(addr.contacts && addr.contacts[0] && addr.contacts[0].phone) ||
				''
			return addr.contacts && addr.contacts.length
				? phoneFormats && !contactPhone.startsWith('+')
					? phoneFormats.replace('{{base}}', contactPhone)
					: contactPhone
				: null
		})
		return {
			from: phoneArray[0],
			to: phoneArray[1]
		}
	}

	const address =
		parcel.address &&
		parcel.address[parcel.status === 'AWAITS_PICKUP' ? 0 : 1]
	const phoneNumber =
		parcel.status === 'AWAITS_PICKUP'
			? getPhoneNumber().from
			: getPhoneNumber().to

	const showExtras =
		parcel.status !== 'DELIVERED' &&
		parcel.extras &&
		Object.keys(parcel.extras).length > 0

	const isShowContacts = () => {
		if (parcel.status === 'DELIVERED') return false
		if (
			parcel.deliveryStage === 'FIRST_MILE' &&
			parcel.status !== 'AWAITS_PICKUP'
		) {
			return false
		}
		return true
	}
	return (
		<IonItemSliding key={parcel.id}>
			<ItemContainer no-lines>
				<StyledIonReorder slot="start" />
				<Wrapper>
					<ItemHeader onClick={e => handleEvents.onClick(e)}>
						<StageTypeContainer stage={parcel.deliveryStage}>
							<span>
								{getShortenDeliveryStageType(
									parcel.deliveryStage
								)}
							</span>
							{parcel.deliveryType && (
								<span>
									{getShortenDeliveryStageType(
										parcel.deliveryType
									)}
								</span>
							)}
						</StageTypeContainer>
						<div>
							<Status active color={COLOR_MAP[parcel.status]}>
								{t(`PARCEL_STATUS.${parcel.status}`)}
							</Status>
						</div>
						<ParcelIdContainer>{`${parcel.id} (${parcel.clientId})`}</ParcelIdContainer>
					</ItemHeader>
					<ItemContent onClick={e => handleEvents.onClick(e)}>
						<Address status={parcel.status}>
							<span>
								{t(
									`Summary.${
										parcel.status === 'AWAITS_PICKUP'
											? 'from'
											: 'to'
									}`
								)}
								:
							</span>
							{address ? address.text : ''}
						</Address>
						<div>
							{showPendingUpload && (
								<OfflineIcon src="assets/icon/offline.png" />
							)}
							{showRetryUpload && (
								<Icon
									onClick={e =>
										handleEvents.onRetryUpload(e, parcel)
									}
								>
									<span role="img" aria-label="Retry upload">
										🔄
									</span>
								</Icon>
							)}
						</div>
					</ItemContent>
					{showExtras && (
						<Extras>
							{JSON.stringify(parcel.extras).replace(
								/"|{|}/g,
								''
							)}
						</Extras>
					)}
					{isShowContacts() && (
						<Contacts>
							{address && address.contacts && (
								<ClientContainer>
									<img src="assets/icon/client.png" alt="" />
									{address.contacts[0].name}
								</ClientContainer>
							)}
							{phoneNumber && (
								<PhoneContainer href={'tel:' + phoneNumber}>
									<img src="assets/icon/phone.png" alt="" />
									{phoneNumber}
								</PhoneContainer>
							)}
						</Contacts>
					)}
				</Wrapper>
			</ItemContainer>
			<IonItemOptions side="end">
				<IonItemOption
					color="danger"
					onClick={() =>
						handleEvents.onDelete(parcel.id, parcel.format)
					}
				>
					<IonIcon size="large" icon={close}></IonIcon>
				</IonItemOption>
			</IonItemOptions>
		</IonItemSliding>
	)
}

export default React.memo(ParcelRecordItem)
