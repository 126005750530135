import axios from 'axios'
import storage, { TOKEN } from 'utils/storage'

const { REACT_APP_API_URL, REACT_APP_NAME, REACT_APP_VERSION } = process.env

const axiosApiClient = axios.create({
	baseURL: REACT_APP_API_URL,
	headers: {
		common: {
			'X-LLM-APPNAME': REACT_APP_NAME,
			'X-LLM-VERSION': REACT_APP_VERSION,
		},
	},
	timeout: 5000,
})

const token = storage.getItem(TOKEN)
if (token) {
	axiosApiClient.defaults.headers.common.Authorization = `Bearer ${token}`
}

axiosApiClient.interceptors.response.use(undefined, error => {
	if (error.response && error.response.status === 401) {
		if (storage.getItem(TOKEN)) {
			alert('Session expired!')
			storage.removeItem(TOKEN)
		}
		window.location.href = '/login'
	}
	return Promise.reject(error)
})

axiosApiClient.CancelToken = axios.CancelToken
axiosApiClient.isCancel = axios.isCancel

export default axiosApiClient
