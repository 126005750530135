import React, { createRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'
import Button from 'components/Button'
import { GROUND } from 'styles/zIndex'
import { noop } from 'utils/helpers'
import Heading from './Heading'

// section heading container, contains section title and clear button
const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

// canvas container
const CanvasContainer = styled.div`
	position: relative;
	border: 1px solid silver;
`

// canvas overlay
const CanvasOverlay = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: ${GROUND + 1};
	background-color: rgba(152, 154, 162, 0.5);
`

const OverlayButton = styled(Button)`
	margin: 1em;
`

// main component, signature section of dropoff form
const Signature = ({ label, isRequired, signature, onSignatureUpdate, fieldId, formTag, fieldTag }) => {
	const { t } = useTranslation()

	const wrapperRef = createRef() // ref to canvas wrapper
	const sigCanvasRef = createRef() // ref to canvas

	const [isOpen, setIsOpen] = useState(false)
	const [canvasProps, setCanvasProps] = useState({ width: 320, height: 200 })

	useEffect(() => {
		let timeout
		if (!signature) {
			timeout = setTimeout(() => {
				const width = wrapperRef.current ? wrapperRef.current.clientWidth : 320
				width && setCanvasProps({ width, height: 200 })
			}, 100)
			sigCanvasRef.current.clear()
		} else {
			sigCanvasRef.current.fromDataURL(signature, { ratio: 1 })
		}
		return () => clearTimeout(timeout)
	}, [signature]) // eslint-disable-line react-hooks/exhaustive-deps

	// clear canvas
	const handleClear = () => {
		sigCanvasRef.current.clear()
		onSignatureUpdate('')
	}

	const handleOpen = () => {
		setIsOpen(true)
	}

	const handleSave = () => {
		setIsOpen(false)
	}

	const handleBegin = e => {
		if (document.activeElement) document.activeElement.blur()
		e.target.focus()
	}

	const handleEnd = () => {
		const image = sigCanvasRef.current.toDataURL('image/png')
		onSignatureUpdate(image)
	}

	return (
		<>
			<TopContainer>
				<Heading label={label} isRequired={isRequired} />
				<Button size="small" onClick={handleClear} className={`gtm-btn-clear-${formTag}.${fieldTag}`}>
					{t('Common.button_clear')}
				</Button>
			</TopContainer>
			<CanvasContainer ref={wrapperRef} id={fieldId}>
				<SignatureCanvas
					canvasProps={canvasProps}
					ref={sigCanvasRef}
					onBegin={handleBegin}
					onEnd={handleEnd}
				/>
				{!isOpen && (
					<CanvasOverlay>
						<OverlayButton onClick={handleOpen} className={`gtm-btn-edit-${formTag}.${fieldTag}`}>
							{t(`Common.button_${signature ? 'edit' : 'add'}`)}
						</OverlayButton>
					</CanvasOverlay>
				)}
			</CanvasContainer>
			{isOpen && (
				<Button expand="block" onClick={handleSave} className={`gtm-btn-save-${formTag}.${fieldTag}`}>
					{t('Common.button_save')}
				</Button>
			)}
		</>
	)
}

Signature.defaultProps = {
	label: 'Signature',
	isRequired: false,
	onSignatureUpdate: noop,
	formTag: '',
	fieldTag: '',
	fieldId: '',
}

export default Signature
