import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
	IonContent,
	IonIcon,
	IonModal,
	IonSegment,
	IonSegmentButton
} from '@ionic/react'
import { close } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import axiosApiClient from 'api/axiosApiClient'
import Button from 'components/Button'
import ErrorDisplay from 'components/ErrorDisplay'
import Form from 'components/Form'
import QRCodeDisplay from 'components/QRCodeDisplay'
import { FIELD_TYPE } from 'components/Form/constants'
import MessageToast from 'components/Form/MessageToast'
import Header from 'components/Header'
import { fetchError, dismissError, makeErrorSelector } from 'redux/error'
import inlineReducer, {
	ARRAY_ADD_END,
	ARRAY_UPDATE_ID,
	DROP_OFF_FAILURE,
	DROP_OFF_SUCCESS,
	DROP_OFF_WAREHOUSE,
	CROSSDOCK_DROP_OFF,
	initForm,
	updateForm,
	getForm
} from 'redux/form'
import {
	RESET,
	SUBMIT_REQUEST,
	SUBMIT_SUCCESS,
	SUBMIT_FAILURE,
	RETRY_REQUEST,
	REVIEWING,
	REJECTED,
	initState as initMessageState,
	updateMessage,
	getMessage
} from 'redux/message'
import { updateSummaryItemStatus } from 'redux/summary'
import { getMeta } from 'redux/meta'
import { white } from 'styles/colors'
import { HEADER, MODAL } from 'styles/zIndex'
import { PARCEL_STATUS, FORM_TAG_STATUS_MAP } from 'utils/constants'
import {
	readSubmitAttempts,
	saveSubmitAttempts,
	clearSubmitAttempts,
	getCrossdock,
	getTranslationValue,
	mapIcon,
	noop,
	setLocation,
	readAllShifts,
	readSummaryData,
	saveSummaryData,
	updateCrossDockItemsInSummary
} from 'utils/helpers'
import log from 'utils/log'
import storage, {
	LOCATION,
	FORM_MODELS,
	FORM_VALUES,
	UPLOAD_ATTEMPTS,
	CROSSDOCKS,
	USER_ID
} from 'utils/storage'
import { pushEvent } from 'utils/tracking'
import {
	generateCrossDockQRCodeData,
	parseCrossDockQRCodeData
} from 'utils/qrcode'

const {
	REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD,
	REACT_APP_ENABLE_LLMP_289_CROSSDOCK,
	REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2
} = process.env

const SUBMIT_TIMEOUT = 10 // seconds
const RETRY_INTERVAL = 10 // seconds
const TOAST_DURATION = 10 // seconds

const UPLOAD_TIMEOUT = 10
const UPLOAD_DELAY = 5

let retryTimer = 0 // retry timer
let dismissTimer = 0 // toast dismiss timer
let countdownTimer = 0 // countdown timer

let countdown = RETRY_INTERVAL // current countdown
let queueLength = 0 // current length of queue

let uploadTimer
let queueData = {}
let queueSort = []
let uploading = false

let cdTimer
let eTag

let tempInlineState = {}

const locs = []

const TabBar = styled.div`
	position: sticky;
	top: -1em;
	z-index: ${HEADER + 1};
	padding-top: 0.5em;
	padding-right: 1em;
	padding-left: 1em;
	background: ${white};
`

const CustomIonModal = styled(IonModal)`
	.ion-page {
		padding-top: var(--ion-safe-area-top, 0);
	}
`

const FormWrapper = styled.div``

const InlineFormWrapper = styled(FormWrapper)`
	padding: 1em;
	margin-top: 1em;

	> div {
		font-size: 20px;
		font-weight: 500;
	}
`

const ModalCloseButton = styled(Button)`
	position: fixed;
	top: calc(var(--ion-safe-area-top, 0) + 0.5em);
	right: 0.5em;
	z-index: ${MODAL + 1};
	--padding-start: 0.5em;
	--padding-end: 0.5em;
`

const showError = (error, fetchError) => {
	if (error.response) {
		fetchError(error) // show error toast
	}
}

// try to submit pending attempts in the queue
export const trySubmitQueue = async (
	updateMessage,
	updateSummaryItemStatus,
	fetchError,
	updateForm
) => {
	clearSubmitAttempts()
	const { queue, updatedAt } = readSubmitAttempts()
	if (queue) {
		queueLength = queue.length
		const removedRefs = [] // refs of items to be removed from the queue

		try {
			countdown = RETRY_INTERVAL
			clearInterval(retryTimer)
			retryTimer = 0
			clearInterval(countdownTimer)
			countdownTimer = 0

			let submissionError

			for (const [index, item] of queue.entries()) {
				updateMessage(RETRY_REQUEST, {
					currentItem: index + 1,
					totalItem: queue.length
				})
				try {
					const { data } = await axiosApiClient.post(
						'form-submissions',
						item
					)
					updateSummary(data, updateSummaryItemStatus)
					removedRefs.push(item.submitterRef)
				} catch (err) {
					submissionError = err
				}
			}
			if (submissionError) throw submissionError
		} catch (error) {
			showError(error, fetchError)
			if (retryTimer === 0) {
				retryTimer = setInterval(() => {
					trySubmitQueue(
						updateMessage,
						updateSummaryItemStatus,
						fetchError,
						updateForm
					)
				}, RETRY_INTERVAL * 1000)

				countdownTimer = setInterval(() => {
					updateMessage(SUBMIT_FAILURE, {
						countdown,
						totalItem: queueLength
					})
					countdown = countdown - 1
				}, 1000)
			}
			updateMessage(SUBMIT_FAILURE, { countdown, totalItem: queueLength })
		} finally {
			// remove successfully submitted items from the queue (if any):
			let { queue: queue2 } = readSubmitAttempts()
			if (queue2)
				queue2 = queue2.filter(
					d => !removedRefs.includes(d.submitterRef)
				)
			saveSubmitAttempts(queue2, updatedAt)
			if (!queue2 || !queue2.length) {
				clearInterval(retryTimer)
				retryTimer = 0
				clearInterval(countdownTimer)
				countdownTimer = 0
				updateMessage(SUBMIT_SUCCESS)

				if (queueSort.length && !uploading) {
					tryUpload(updateMessage, updateForm)
				}
			}
			queueLength = queue2 ? queue2.length : 0
		}
	} else {
		clearInterval(retryTimer)
		retryTimer = 0
		clearInterval(countdownTimer)
		countdownTimer = 0
		updateMessage(RESET)
	}
}

// do submit
// TODO: refactor params:
export const submit = async (
	data,
	updateMessage,
	updateSummaryItemStatus,
	fetchError,
	inlineForm,
	submitterRefs
) => {
	try {
		// submit shiftId in meta:
		try {
			const s = readAllShifts()
			if (
				s &&
				s.today &&
				s.today.status === 'APPROVED' &&
				moment(s.today.date).isSame(moment(), 'd')
			) {
				data.meta = { ...data.meta, shiftId: s.today.id }
			}
		} catch (err) {
			log.error('Failed to read shifts', null, err.stack)
		}
		if (!inlineForm) {
			data.submitterRef =
				submitterRefs && submitterRefs[data.formId]
					? submitterRefs[data.formId]
					: uuid()
			updateMessage(SUBMIT_REQUEST)
		} else {
			data.submitterRef = inlineForm.ref
			data.fields.push({
				tag: 'ROOT_FORM_SUBMITTER_REF',
				values: [inlineForm.rootRef]
			})
		}

		// before making API request, save item to queue:
		let { queue } = readSubmitAttempts()
		if (queue) {
			queue.push(data)
		} else {
			queue = [data]
		}
		saveSubmitAttempts(queue)
		const result = await axiosApiClient.post('form-submissions', data, {
			timeout: SUBMIT_TIMEOUT * 1000
		})
		// if API request is successful, remove the item from queue:
		let { queue: queue2 } = readSubmitAttempts()
		if (queue2) {
			queue2 = queue2.filter(d => d.submitterRef !== data.submitterRef)
		}
		saveSubmitAttempts(queue2)
		if (result.data) {
			updateSummary(result.data, updateSummaryItemStatus)
		}

		if (!inlineForm) updateMessage(SUBMIT_SUCCESS)
	} catch (error) {
		showError(error, fetchError)
		trySubmitQueue(
			updateMessage,
			updateSummaryItemStatus,
			fetchError,
			updateForm
		) // initiate retry attempts
	}
}

// save summary data, called on form submission:
export const saveSummary = (data, clientId) => {
	const formTag = data.formId.substring(data.formId.indexOf('_') + 1)
	const status = data.status || FORM_TAG_STATUS_MAP[formTag]
	if (!status) return // don't save if status is unknown
	const isRejected = data.fields.some(
		f => f.tag === 'DOCKSTATUS' && f.values[0] === REJECTED
	)
	if (isRejected) return // don't save if this is a REJECTED CROSSDOCK
	try {
		const summary = readSummaryData()
		const parcels = summary.parcels || {}
		const orders = summary.orders || {}
		const newData = {}
		let orderNo
		let failureReason = ''
		data.fields.forEach(field => {
			if (field.tag === 'BARCODE') {
				field.values.forEach((value, i) => {
					let orderId
					if (!clientId) {
						// get clientId for each value from meta:
						if (field.meta) {
							clientId = field.meta[i][0].clientId
						}
					}
					if (field.meta) {
						orderId = field.meta[i][0].orderId
					}
					newData[value] = {
						clientId,
						...(orderId && { orderId }),
						pending: true
					}
				})
			} else if (field.tag === 'ORDER_NO') {
				orderNo = field.values[0]
			} else if (field.tag === 'REASONS_FAILURE') {
				failureReason = field.values[0]
			}
		})
		if (orderNo) {
			// if this submission includes order no
			Object.values(newData).forEach(d => {
				d.orderId = orderNo // assign the order no to each parcel
				if (orders[orderNo]) {
					// if there is an existing item with only order no (no parcel ID), e.g. FedEx first mile pickup
					// move the data from order to parcel:
					d.address = orders[orderNo].address
					d.deliveryBy = orders[orderNo].deliveryBy
					d.logs = orders[orderNo].logs
					d.pickupAt = orders[orderNo].pickupAt
				}
			})
			delete orders[orderNo] // then delete the order
		}
		Object.entries(newData).forEach(([k, v]) => {
			if (parcels[k]) {
				// if there is existing data, update it
				parcels[k] = { ...parcels[k], ...v }
			} else {
				// otherwise save everything
				parcels[k] = v
			}
			parcels[k].status =
				status === PARCEL_STATUS.FAILED &&
				failureReason.startsWith('17')
					? PARCEL_STATUS.RESCHEDULED
					: status
			if (!parcels[k].logs) {
				parcels[k].logs = []
			}
			parcels[k].logs.push({
				status: parcels[k].status,
				time: data.submittedAt
			})
		})
		saveSummaryData({
			...summary,
			parcels,
			orders,
			updatedAt: new Date().toISOString()
		})
	} catch (err) {
		log.error('saveSummary failed', null, err.stack)
	}
}

const getParcelStatusFromFormTag = (formTag, isRescheduled) => {
	switch (formTag) {
		case DROP_OFF_FAILURE:
			return isRescheduled
				? PARCEL_STATUS.RESCHEDULED
				: PARCEL_STATUS.FAILED
		case DROP_OFF_SUCCESS:
			return PARCEL_STATUS.DELIVERED
		case DROP_OFF_WAREHOUSE:
			return PARCEL_STATUS.RETURNED
		case CROSSDOCK_DROP_OFF:
			return PARCEL_STATUS.PENDING
		default:
			return PARCEL_STATUS.IN_PROGRESS
	}
}

// update summary data, called when form submission is successful
// (mostly just to re-render summary item, only touch storage to remove pending flag):
const updateSummary = (data, updateSummaryItemStatus) => {
	const formTag = data.formId.substring(data.formId.indexOf('_') + 1)
	const status = FORM_TAG_STATUS_MAP[formTag]
	if (!status) return
	const isRejected = data.fields.some(
		f => f.tag === 'DOCKSTATUS' && f.values[0] === REJECTED
	)
	if (isRejected) return
	try {
		const summary = readSummaryData()
		const parcels = summary.parcels || {}
		const isRescheduled = data.fields.some(
			f => f.tag === 'REASONS_FAILURE' && f.values[0].startsWith('17')
		)
		data.fields.forEach(field => {
			if (field.tag === 'BARCODE') {
				// determine new status after quick action submission:
				const status =
					data.status ||
					getParcelStatusFromFormTag(formTag, isRescheduled)
				field.values.forEach(value => {
					if (!data.pending) {
						delete parcels[value].pending // delete pending flag
					}
					parcels[value].status = status
					updateSummaryItemStatus(value, status, true) // re-render
				})
			}
		})
		saveSummaryData({
			...summary,
			parcels,
			updatedAt: new Date().toISOString()
		})
	} catch (err) {
		log.error('updateSummary failed', null, err.stack)
	}
}

// try to upload photos in the queue:
const tryUpload = async (updateMessage, updateForm) => {
	uploading = true
	const { PHOTO } = FIELD_TYPE

	clearTimeout(uploadTimer)
	uploadTimer = null
	if (!retryTimer) {
		clearInterval(countdownTimer)
		countdownTimer = 0
	}

	if (!queueSort.length) {
		console.log('tryUpload - nothing in queue')
	} else {
		console.log('tryUpload - begin queueData', queueData)
		console.log('tryUpload - begin queueSort', queueSort)

		const idsToRemove = []

		for (const [index, id] of queueSort.entries()) {
			updateMessage(RETRY_REQUEST, {
				field: PHOTO,
				currentItem: index + 1,
				totalItem: queueSort.length
			})
			const v = queueData[id]
			try {
				updateForm(ARRAY_UPDATE_ID, v.value.tag, {
					...v.value,
					status: 'Form.message_uploading_photo'
				})
				await axiosApiClient.post(
					'upload',
					{ name: id, data: v.value.data },
					{
						cancelToken: v.source.token,
						timeout: UPLOAD_TIMEOUT * 1000
					}
				)
				console.log('tryUpload - success', id)
				idsToRemove.push(id)
				const { status: _, data: __, ...rest } = v.value
				updateForm(ARRAY_UPDATE_ID, v.value.tag, rest)
			} catch (e) {
				if (axiosApiClient.isCancel(e)) {
					log.info(`tryUpload - canceled: ${e.message}`)
					idsToRemove.push(id)
				} else {
					log.error(
						'tryUpload - failure',
						{ category: 'API' },
						e.stack
					)
					updateForm(ARRAY_UPDATE_ID, v.value.tag, {
						...v.value,
						status: 'Form.message_upload_pending_short'
					})
				}
			}
		}

		for (const id of idsToRemove) {
			delete queueData[id]
			const i = queueSort.indexOf(id)
			if (i > -1) {
				queueSort.splice(i, 1)
			}
		}

		if (queueSort.length) {
			if (!uploadTimer && !retryTimer && !queueLength) {
				uploadTimer = setTimeout(() => {
					tryUpload(updateMessage, updateForm)
				}, UPLOAD_DELAY * 1000)

				countdown = UPLOAD_DELAY
				updateMessage(SUBMIT_FAILURE, {
					field: PHOTO,
					countdown: countdown--,
					totalItem: queueSort.length
				})
				countdownTimer = setInterval(() => {
					updateMessage(SUBMIT_FAILURE, {
						field: PHOTO,
						countdown: countdown--,
						totalItem: queueSort.length
					})
				}, 1000)
			}
			if (idsToRemove.length) saveUploadQueue()
		} else {
			clearTimeout(uploadTimer)
			uploadTimer = null
			if (!retryTimer) {
				clearInterval(countdownTimer)
				countdownTimer = 0
			}
			storage.removeItem(UPLOAD_ATTEMPTS)
			updateMessage(SUBMIT_SUCCESS, { field: PHOTO })
		}

		console.log('tryUpload - end queueData', queueData)
		console.log('tryUpload - end queueSort', queueSort)
	}
	uploading = false
}

const saveUploadQueue = () => {
	try {
		const queue = queueSort.map(id => queueData[id].value)
		const updatedAt = new Date().toISOString()
		storage.setItem(UPLOAD_ATTEMPTS, JSON.stringify({ queue, updatedAt }))
	} catch (e) {
		log.error('Failed to save upload queue to storage', null, e.stack)
	}
}

// main component, the redux-connected form
const ConnectedForm = ({
	translations,
	icon,
	clients,
	clientId,
	formGroup,
	displayIndex,
	onIndexChange,
	models,
	values,
	initForm,
	updateForm,
	message,
	updateMessage,
	error,
	fetchError,
	dismissError,
	meta,
	updateSummaryItemStatus
}) => {
	const { t } = useTranslation()

	// ref to content
	const contentRef = useRef(null)
	// scroll to top of content
	const scrollToTop = () => {
		contentRef.current.scrollToTop()
	}
	const [index, setIndex] = useState(0)

	const [submitting, setSubmitting] = useState(false)

	const [isQrOpen, setIsQrOpen] = useState(false)
	const [qrData, setQrData] = useState({
		data: { BARCODE: [] },
		meta: { numberOfItems: 0 }
	})
	const [isFormLocked, setIsFormLocked] = useState(false)

	const [inlineFormModel, setInlineFormModel] = useState(null)
	const [inlineFormValue, setInlineFormValue] = useState(null)
	const [isInlineFormOpen, setIsInlineFormOpen] = useState(false)

	const [submitterRefs, setSubmitterRefs] = useState({})

	const getAllFields = () =>
		[].concat(...models.map(form => [...form.fields]))

	// init form values
	const initializeForm = () => {
		const fields = getAllFields()
		const json = JSON.parse(storage.getItem(FORM_VALUES)) || {}
		initForm(fields, json)

		const formsSubmitterRefs = models.reduce((acc, { id }) => {
			acc[id] = uuid()
			return acc
		}, {})
		setSubmitterRefs(formsSubmitterRefs)
	}

	// on componentDidMount:
	useEffect(() => {
		initializeForm()
		trySubmitQueue(
			updateMessage,
			updateSummaryItemStatus,
			fetchError,
			updateForm
		)

		if (REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD === 'true') {
			// load photo upload queue from storage:
			const { queue } = JSON.parse(storage.getItem(UPLOAD_ATTEMPTS)) || {}
			if (queue) {
				for (const q of queue) {
					if (!queueData[q.id]) {
						const source = axiosApiClient.CancelToken.source()
						queueSort.push(q.id)
						queueData[q.id] = { source, value: q }
					}
				}
				if (queueSort.length && !uploading) {
					tryUpload(updateMessage, updateForm)
				}
			}
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const handlePhotoAdded = (photo, updateFunc) => {
		if (REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD !== 'true') return
		// populate photo upload queue:
		if (photo.data && !queueData[photo.id]) {
			const source = axiosApiClient.CancelToken.source()
			queueSort.push(photo.id)
			queueData[photo.id] = { source, value: photo }
		}
		if (queueSort.length && !uploading) {
			tryUpload(updateMessage, updateFunc || updateForm)
		}
	}

	const handleInlinePhotoAdded = photo => {
		handlePhotoAdded(photo, updateInlineForm)
	}

	const handleInlineFormClick = tag => {
		const allForms = JSON.parse(storage.getItem(FORM_MODELS))
		const clientPrefix = clientId || 'COMMON'
		if (
			allForms &&
			allForms[clientPrefix] &&
			allForms[clientPrefix].length
		) {
			let inlineForm = allForms[clientPrefix].find(
				f => f.id === `${clientPrefix}_${tag}`
			)
			// fallback to COMMON Inline Form
			if (clientId && !inlineForm) {
				inlineForm = allForms['COMMON'].find(
					f => f.id === `COMMON_${tag}`
				)
			}
			if (inlineForm) {
				// init inline form values:
				const inlineValues = inlineForm.fields.reduce((acc, field) => {
					const { tag, isArray } = field
					acc[tag] = isArray ? [] : ''
					return acc
				}, {})
				setInlineFormModel(inlineForm)
				// merge with values copied from main form:
				setInlineFormValue({ ...inlineValues, ...values })
				setIsInlineFormOpen(true)
			}
		}
	}

	useEffect(() => {
		if (Object.keys(values).length !== 0) {
			// save form values to local storage:
			const json = JSON.parse(storage.getItem(FORM_VALUES)) || {}
			storage.setItem(FORM_VALUES, JSON.stringify({ ...json, ...values }))
		}
	}, [values]) // eslint-disable-line react-hooks/exhaustive-deps

	// if message variant changed to SUCCESS start auto-dismiss timer, otherwise stop it
	useEffect(() => {
		if (message.autoDismiss) {
			startDismissTimer()
		} else {
			stopDismissTimer()
		}
	}, [message.autoDismiss]) // eslint-disable-line react-hooks/exhaustive-deps

	const scrollToFirstEmptyField = () => {
		let emptyField
		// search for empty field:
		Object.entries(values).some(([k, v]) => {
			if (v === '' || (Array.isArray(v) && v.length === 0)) {
				emptyField = k
				return true
			}
			return false
		})

		if (emptyField) {
			const elem = document.getElementById(`${formGroup}-${emptyField}`)
			if (elem) {
				setTimeout(() => {
					if (elem.scrollIntoViewIfNeeded)
						elem.scrollIntoViewIfNeeded(true)
				})
			}
		}
	}

	const handleFormTypeChange = e => {
		const newIndex = e.target.value
		if (newIndex > -1) {
			onIndexChange(newIndex)
			setIndex(newIndex)

			scrollToFirstEmptyField()
			// tracking:
			pushEvent('Form Select', { formId: models[newIndex].id })
		}
	}

	// on action
	const handleFormAction = (type, tag, value, fields, index) => {
		if (tag === FIELD_TYPE.BARCODE && type === ARRAY_ADD_END) {
			locs.push(setLocation(value))
		}
		updateForm(type, tag, value, fields, index)
	}

	const handleInlineFormAction = (type, tag, value, fields, index) => {
		const newState = inlineReducer(inlineFormValue, {
			type,
			tag,
			value,
			fields,
			index
		})
		setInlineFormValue(newState)
		tempInlineState = newState
	}

	const updateInlineForm = (type, tag, value, fields, index) => {
		const newState = inlineReducer(tempInlineState, {
			type,
			tag,
			value,
			fields,
			index
		})
		setInlineFormValue(newState)
		tempInlineState = newState
	}

	const handleFormReset = () => {
		setIsFormLocked(false)
		initForm(getAllFields())
		setSubmitterRefs({ ...submitterRefs, [models[index].id]: uuid() })
	}

	// on submit
	const handleFormSubmit = async (
		fieldValues,
		extraMeta,
		isFlag,
		crossDock,
		inlineForm
	) => {
		// reset form to initial state:
		setIsFormLocked(false)
		// don't reset main form if submission comes from inline form:
		if (!inlineForm) {
			initForm(getAllFields())
			setSubmitterRefs({ ...submitterRefs, [models[index].id]: uuid() })
		}
		Object.assign(meta, extraMeta)
		setSubmitting(true)
		locs.push(setLocation(meta))
		await Promise.all(locs)
		locs.length = 0

		const model = models[index]
		const cdAttr = model.attributes.crossdock || {}

		const locationId = storage.getItem(LOCATION)
		const userId = storage.getItem(USER_ID)
		const data = {
			formId: inlineForm ? inlineForm.id : model.id,
			clientId,
			fields: fieldValues.filter(field => field.values.length),
			submittedAt: new Date().toISOString(),
			meta,
			isFlag,
			locationId,
			userId
		}

		// tracking:
		pushEvent('Form Submit', { formId: data.formId })

		if (!crossDock.DOCKID) crossDock.DOCKID = uuid()
		if (!crossDock.DOCKSTATUS) crossDock.DOCKSTATUS = REVIEWING
		if (REACT_APP_ENABLE_LLMP_289_CROSSDOCK === 'true' && cdAttr.position) {
			data.fields.push({ tag: 'DOCKID', values: [crossDock.DOCKID] })
			data.fields.push({
				tag: 'DOCKSTATUS',
				values: [crossDock.DOCKSTATUS]
			})
		}
		if (crossDock.DOCKSTATUS !== REJECTED) {
			const summaryData = readSummaryData()
			const deliveryBarcodeIndex = data.fields.findIndex(
				item => item.tag === 'BARCODE'
			)
			const dataDeliveryId =
				deliveryBarcodeIndex >= 0
					? data.fields[deliveryBarcodeIndex].values[0]
					: undefined
			if (dataDeliveryId) {
				const currentExistDelivery = summaryData.parcels[dataDeliveryId]
				saveSummary(
					{
						...data,
						pending: true,
						status: currentExistDelivery
							? currentExistDelivery.status
							: null
					},
					clientId
				)
			}
		}
		submit(
			data,
			updateMessage,
			updateSummaryItemStatus,
			fetchError,
			inlineForm,
			submitterRefs
		)
		if (!inlineForm) scrollToTop()
		setSubmitting(false)

		if (REACT_APP_ENABLE_LLMP_289_CROSSDOCK === 'true' && cdAttr.position) {
			if (cdAttr.position === 'inbound' && cdAttr.qrFieldTags) {
				createQrCode(crossDock.DOCKID, data, cdAttr.qrFieldTags)
			}
			if (cdAttr.position === 'outbound') {
				updateMessage(crossDock.DOCKSTATUS, { priority: true })
			}
		}

		if (REACT_APP_ENABLE_LLMP_286_PHOTO_UPLOAD === 'true') {
			saveUploadQueue()
		}
	}

	const handleInlineFormSubmit = (
		fieldValues,
		extraMeta,
		isFlag,
		crossDock
	) => {
		const ref = uuid()
		const rootRef = submitterRefs[models[index].id]
		const tag = models[index].fields.find(f => f.type === 'INLINE_FORM').tag
		handleFormSubmit(fieldValues, extraMeta, isFlag, crossDock, {
			id: inlineFormModel.id,
			ref,
			rootRef
		})
		updateForm('STRING_CHANGE', tag, ref)
		setInlineFormValue({})
		setIsInlineFormOpen(false)
	}

	const handleFieldCancel = id => {
		if (queueData[id] && queueData[id].source) {
			queueData[id].source.cancel('Photo deleted, request canceled.')
		}
	}

	const createQrCode = (id, data, tags) => {
		const qrCodeData = generateCrossDockQRCodeData(id, data, tags)

		saveCrossDock(qrCodeData.data, data.submittedAt)
		setQrData(qrCodeData)
		setIsQrOpen(true)
		if (REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2 === 'true') {
			startPolling(qrCodeData.data, data.submittedAt)
		}
	}

	const saveCrossDock = (data, submittedAt) => {
		try {
			let crossDocks = JSON.parse(storage.getItem(CROSSDOCKS)) || {}
			if (crossDocks.list) {
				const i = crossDocks.list.findIndex(
					d => d.data.DOCKID === data.DOCKID
				)
				if (i > -1) {
					crossDocks.list[i] = { data, submittedAt }
				} else {
					crossDocks.list.push({ data, submittedAt })
				}
			} else {
				crossDocks.list = [{ data, submittedAt }]
			}
			crossDocks.updatedAt = new Date().toISOString()
			storage.setItem(CROSSDOCKS, JSON.stringify(crossDocks))
		} catch (err) {
			log.error('saveCrossDock failed', null, err.stack)
		}
	}

	const startPolling = (data, submittedAt) => {
		clearInterval(cdTimer)
		cdTimer = setInterval(async () => {
			const { DOCKID } = data
			const res = await getCrossdock(DOCKID, eTag)
			if (!res) return

			const { status } = res.data
			if (res.headers.etag) {
				eTag = res.headers.etag
			}
			if (status !== 'REVIEWING') {
				data.DOCKSTATUS = status
				setIsQrOpen(false)
				updateMessage(status, { priority: true })
				saveCrossDock(data, submittedAt)
				updateCrossDockItemsInSummary(
					parseCrossDockQRCodeData(data.BARCODE).map(
						br => br[0].value
					),
					status
				)
				clearInterval(cdTimer)
			}
		}, RETRY_INTERVAL * 1000) // 10 seconds..
	}

	const handleQrClosed = () => {
		setIsQrOpen(false)
		clearInterval(cdTimer)
		eTag = ''
	}

	// for testing purposes only:
	// eslint-disable-next-line no-unused-vars
	const createTestQrCode = (idLength, listLength) => {
		const makeRandomId = length => {
			let result = ''
			const chars = '0123456789'
			for (let i = 0; i < length; i += 1) {
				result += chars.charAt(Math.floor(Math.random() * chars.length))
			}
			return result
		}
		const BARCODE = []
		for (let i = 0; i < listLength; i += 1) {
			BARCODE.push(makeRandomId(idLength))
		}
		const qrCodeData = {
			DOCKID: uuid(),
			BARCODE,
			ORDER_NO: ['AW', 'MW']
		}
		setQrData(qrCodeData)
		console.log(new URLSearchParams(qrCodeData).toString())
		console.log(new URLSearchParams(qrCodeData).toString().length)
		setIsQrOpen(true)
	}

	// start timer that will dismiss success message
	const startDismissTimer = () => {
		if (dismissTimer) {
			stopDismissTimer()
		}
		dismissTimer = setTimeout(() => {
			if (message.autoDismiss) {
				updateMessage(RESET)
				stopDismissTimer()
			}
		}, TOAST_DURATION * 1000)
	}

	// stop that timer
	const stopDismissTimer = () => {
		clearTimeout(dismissTimer)
		dismissTimer = 0
	}

	const handleErrorDismissed = () => {
		if (error) {
			dismissError(error.errorName) // trigger redux action to remove the error
		}
	}

	const { attributes } = models[index]
	const bgColor = displayIndex > -1 ? attributes.bgColor : null
	const getFormIcon = ({ tag, attributes }) =>
		attributes.icon || `assets/icon/${mapIcon(tag)}`
	let headerTitle = getTranslationValue(translations, t(`${formGroup}.title`))
	if (clientId) headerTitle += ` (${clientId})`

	return (
		<>
			<ErrorDisplay
				isOpen={!!error}
				blocking={error && error.blocking}
				requestId={error && error.requestId}
				message={error && error.message}
				onDismiss={handleErrorDismissed}
			/>
			{/* Header */}
			<Header
				title={headerTitle}
				icon={icon}
				onClickTitle={scrollToTop}
				bgColor={bgColor}
				hideMenu={isFormLocked}
			/>
			{/* Message Toast */}
			{message.variant && (
				<MessageToast
					type={message.variant}
					message={t(message.content.key, message.content.data)}
					buttonText={t(
						message.dismissable
							? 'Common.button_close'
							: 'Form.button_retry_now'
					)}
					buttonClick={
						message.dismissable
							? () => updateMessage(RESET)
							: () =>
									trySubmitQueue(
										updateMessage,
										updateSummaryItemStatus,
										fetchError,
										updateForm
									)
					}
					shouldShowButton={message.hasAction}
				/>
			)}
			{/* Content */}
			<IonContent className="ion-padding" ref={contentRef}>
				{!isFormLocked && (
					<TabBar>
						{displayIndex < 0 && (
							<div
								style={{
									marginBottom: '1em',
									textAlign: 'center'
								}}
							>
								{t('Form.label_select_form', {
									form: t(`${formGroup}.title`)
								})}
								:
							</div>
						)}
						{/* form type */}
						{models.length > 1 && (
							<IonSegment
								color="dark"
								value={displayIndex}
								onIonChange={handleFormTypeChange}
							>
								{models.map((form, i) => (
									<IonSegmentButton
										key={i}
										value={i}
										id={`${form.tag}-button`}
										className={`gtm-btn-form-select-${form.tag}`}
									>
										<img
											src={getFormIcon(form)}
											height="32"
											alt={form.tag}
											className={`gtm-btn-form-select-${form.tag}`}
										/>
										{form
											? getTranslationValue(
													form.translations,
													form.tag
											  )
											: form.tag}
									</IonSegmentButton>
								))}
							</IonSegment>
						)}
					</TabBar>
				)}
				{displayIndex > -1 && (
					<FormWrapper>
						<Form
							clients={clients}
							clientId={clientId}
							model={models[index]}
							values={values}
							formGroup={formGroup}
							submitting={submitting}
							onAction={handleFormAction}
							onLock={() => setIsFormLocked(true)}
							onReset={handleFormReset}
							onSubmit={handleFormSubmit}
							onCancel={handleFieldCancel}
							onPhotoAdded={handlePhotoAdded}
							onInlineFormClick={handleInlineFormClick}
						/>
					</FormWrapper>
				)}
				<QRCodeDisplay
					isOpen={isQrOpen}
					onClose={handleQrClosed}
					data={qrData.data}
					headingLabel={`${t('Form.label_only_number_item')}: `}
					headingValue={qrData.meta.numberOfItems}
					id={qrData.data.DOCKID}
				/>
				<CustomIonModal
					isOpen={isInlineFormOpen}
					backdropDismiss={false}
					animated
					mode="ios"
				>
					<IonContent>
						<InlineFormWrapper>
							{inlineFormModel && (
								<div>
									{getTranslationValue(
										inlineFormModel.translations,
										inlineFormModel.tag
									)}
								</div>
							)}
							<ModalCloseButton
								shape="round"
								onClick={() => setIsInlineFormOpen(false)}
								className="gtm-btn-inline-form-close"
							>
								<IonIcon icon={close} />
							</ModalCloseButton>
							<Form
								clients={clients}
								clientId={clientId}
								model={inlineFormModel}
								values={inlineFormValue}
								formGroup={`${formGroup}-inline`}
								submitting={submitting}
								onAction={handleInlineFormAction}
								onSubmit={handleInlineFormSubmit}
								onPhotoAdded={handleInlinePhotoAdded}
								onCancel={handleFieldCancel}
							/>
						</InlineFormWrapper>
					</IonContent>
				</CustomIonModal>
			</IonContent>
		</>
	)
}

ConnectedForm.defaultProps = {
	translations: [],
	icon: '',
	clients: [],
	formGroup: '',
	displayIndex: 0,
	models: [],
	values: {},
	message: initMessageState,
	onIndexChange: noop,
	initForm: noop,
	updateForm: noop,
	updateMessage: noop,
	updateSummaryItemStatus: noop
}

const mapStateToProps = state => ({
	values: getForm(state),
	message: getMessage(state),
	error: makeErrorSelector(['FETCH'])(state),
	meta: getMeta(state)
})

export default connect(mapStateToProps, {
	initForm,
	updateForm,
	updateMessage,
	fetchError,
	dismissError,
	updateSummaryItemStatus
})(ConnectedForm)
