import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import {
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonSpinner
} from '@ionic/react'
import styled from 'styled-components'
import Button from 'components/Button'
import Header from 'components/Header'
import { danger, silver } from 'styles/colors'
import {
	changeLocale,
	checkTokenValidity,
	coordDistance,
	getCurrentPosition,
	getLocations,
	setFocus,
	getValueByPath
} from 'utils/helpers'
import log from 'utils/log'
import storage, { LAST_PAGE, LOCATION, LOCALE, PHONE } from 'utils/storage'
import axiosApiClient from 'api/axiosApiClient'

const { REACT_APP_TELEGRAM_BOT_URL, REACT_APP_ENABLE_SMS_LOGIN } = process.env

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1em;
`

const Section = styled.div`
	display: flex;
`

const InputWrapper = styled(IonItem)`
	margin: 0.5em 0 1.5em 0;
	border: 1px solid ${({ error }) => (error ? danger : silver)};

	--min-height: 38px;
	--inner-padding-end: 0.25em;
`

const SelectWrapper = styled(IonItem)`
	flex: 1;
	margin: 0.5em 0 1.5em 0;
	border: 1px solid ${({ error }) => (error ? danger : silver)};

	--min-height: 38px;
	--inner-padding-end: 0.25em;
`

const StyledSelect = styled(IonSelect)`
	min-width: 100%;
	padding: 0;
`

const InputError = styled.div`
	margin: -1em 0 1em 0;
	color: ${danger};
`

const StyledButton = styled(Button)`
	margin: 1.5em 0;
`

const Tips = styled.div`
	color: grey;
	text-align: center;
`

const Footer = styled.footer`
	position: absolute;
	right: 1em;
	bottom: 1em;
	left: 1em;
`

let locChanged = false

const Login = ({ history, location }) => {
	const { t } = useTranslation()
	const [phone, setPhone] = useState('')
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	// TODO: may want to move some of these states to redux and pass here as props..
	const [locations, setLocations] = useState([])
	const [selectedLocationIndex, setSelectedLocationIndex] = useState(-1)
	const [selectedLocaleIndex, setSelectedLocaleIndex] = useState(-1)

	useEffect(() => {
		const fetchLocations = async () => {
			const data = await getLocations()
			setLocations(data)
			return data
		}

		const preSelectValues = async () => {
			const locationsData = await fetchLocations()
			// pre-select location with fallback:
			let locationIndex = -1
			const savedLocation = storage.getItem(LOCATION)
			if (savedLocation) {
				// 1. use previously saved location:
				locationIndex = locationsData.findIndex(
					location => location.id === savedLocation
				)
			}
			if (locationIndex < 0) {
				// 2. use current position:
				try {
					const { coords } = await getCurrentPosition(2)
					if (coords) {
						const { latitude: lat, longitude: lng } = coords
						let index = 0
						// search if current position is within radius of any location:
						const withinRadius = locationsData.some(
							(location, i) => {
								const { geo } = location
								// calculate distance between current position and each location:
								const distance = coordDistance(
									{ lat, lng },
									{
										lat: parseFloat(geo.lat),
										lng: parseFloat(geo.lng)
									}
								)
								index = i
								return distance <= geo.radius
							}
						)
						if (withinRadius) locationIndex = index
					}
				} catch (e) {
					log.error('Failed to get current position', null, e.stack)
				}
			}
			if (locationIndex < 0) {
				// 3. use the first as default location:
				locationIndex = 0
			}
			setSelectedLocationIndex(locationIndex)
			storage.setItem(LOCATION, locationsData[locationIndex].id)
			log.changeLocation(locationsData[locationIndex].id)

			// pre-select locale with fallback:
			let localeIndex = -1
			const savedLocale = storage.getItem(LOCALE)
			if (savedLocale) {
				// 1. use previously saved locale:
				localeIndex = locationsData[locationIndex].locales.findIndex(
					locale => locale.id === savedLocale
				)
			}
			if (localeIndex < 0) {
				// 2. use browser language:
				const browserLang = navigator.language.split('-')[0]
				localeIndex = locationsData[locationIndex].locales.findIndex(
					locale => locale.id.startsWith(browserLang)
				)
			}
			if (localeIndex < 0) {
				// 3. use the first as default locale:
				localeIndex = 0
			}
			setSelectedLocaleIndex(localeIndex)
			changeLocale(locationsData[locationIndex].locales[localeIndex].id)
		}
		preSelectValues()
	}, [])

	useEffect(() => {
		if (selectedLocationIndex < 0 || !locChanged) return
		// when location is changed, change locale to the new location's first locale:
		updateLocale(0)
		locChanged = false
	}, [selectedLocationIndex]) // eslint-disable-line react-hooks/exhaustive-deps

	const phoneInputRef = useRef()
	useEffect(() => {
		if (phoneInputRef.current) {
			setTimeout(() => {
				setFocus(phoneInputRef)
			}, 200)
		}
	}, [phoneInputRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

	const renderMultilingual = translations => {
		if (translations.length === 1) return translations[0].value
		const locale =
			locations[selectedLocationIndex].locales[selectedLocaleIndex]
		let localeId
		if (locale) localeId = locale.id
		return translations.reduce((acc, item, index) => {
			const mainCon = localeId ? item.locale === localeId : index === 0
			if (mainCon) return `${item.value}${acc}`
			// TODO: ignore repeated values, e.g. same string in different locales
			return `${acc}/${item.value}`
		}, '')
	}

	const handleLocationChange = e => {
		locChanged = true
		setSelectedLocationIndex(e.target.value)
		storage.setItem(LOCATION, locations[e.target.value].id)
		log.changeLocation(locations[e.target.value].id)
	}

	const handleLocaleChange = e => {
		const localeIndex = e.target.value
		if (localeIndex < 0) return
		const temp = selectedLocationIndex
		setSelectedLocationIndex(-1) // trigger IonSelect UI update
		setSelectedLocationIndex(temp)
		updateLocale(localeIndex)
	}

	const updateLocale = localeIndex => {
		setSelectedLocaleIndex(-1) // trigger IonSelect UI update:
		setTimeout(() => setSelectedLocaleIndex(localeIndex))
		const localeId =
			locations[selectedLocationIndex].locales[localeIndex].id
		changeLocale(localeId)
	}

	const handlePhoneChange = e => {
		setPhone(e.target.value)
		setError('')
	}

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			handleTelegramClick() // just for us to log in easier when testing in desktop browser
			e.preventDefault()
		}
	}

	const handleTelegramClick = () => {
		handleClick(['TELEGRAM'])
	}

	const handleSmsClick = () => {
		handleClick(['SMS', 'TELEGRAM'])
	}

	const handleClick = methods => {
		const validPhone = validatePhoneNumber()
		if (validPhone) {
			sendLoginRequest(methods, validPhone)
		} else {
			setError('Login.error_invalid_phone')
		}
	}

	const validatePhoneNumber = () => {
		const { regexpIntl, regexpLocal, templateIntl } =
			locations[selectedLocationIndex].phoneFormats
		const intlMatches = phone.match(new RegExp(regexpIntl))
		if (intlMatches && intlMatches.length) {
			const [full] = intlMatches
			return full
		}
		const localMatches = phone.match(new RegExp(regexpLocal))
		if (localMatches && localMatches.length) {
			const [, base] = localMatches
			// TODO: Align interpolation template format
			return t(templateIntl.replace('{{', '{').replace('}}', '}'), {
				base
			})
		}
		return null
	}

	const sendLoginRequest = async (confirmVia, phone) => {
		setError('')
		const locationId = locations[selectedLocationIndex].id
		const locale =
			locations[selectedLocationIndex].locales[selectedLocaleIndex].id
		const data = {
			confirmValue: phone,
			confirmVia,
			locale,
			location: locationId
		}
		setLoading(true)
		try {
			const response = await axiosApiClient.post('/v1/login', data)
			const { id, reference, expireAt } = response.data // to be used when sending patch request with confirmation code
			setPhone('') // clear input
			storage.setItem(PHONE, phone)
			// go to verification page:
			const { state } = location
			history.push({
				pathname: '/login/verification',
				search: `?id=${id}&reference=${reference}&expireAt=${expireAt}`,
				...(state && { state: { referrer: state.referrer } })
			})
		} catch (error) {
			log.error('sendLoginRequest', { category: 'API' }, error.stack)
			const errorMessage = getValueByPath(
				error,
				'response.data.errors.0.detail',
				'Login.error_login'
			)
			setError(errorMessage)
		}
		setLoading(false)
	}

	if (checkTokenValidity()) {
		const lastPage = storage.getItem(LAST_PAGE)
		if (lastPage) {
			window.location.replace(lastPage)
		}
	}

	return (
		<IonPage>
			<Header title={t('Login.title')} hideMenu />
			<IonContent>
				<Container>
					{selectedLocationIndex < 0 || selectedLocaleIndex < 0 ? (
						<IonSpinner style={{ margin: 'auto' }} />
					) : (
						<>
							<form>
								<Section>
									<SelectWrapper lines="none">
										<StyledSelect
											interface="popover"
											interfaceOptions={{
												animated: false
											}}
											value={selectedLocationIndex}
											onIonChange={handleLocationChange}
											id="location"
										>
											{locations.map(
												(
													{ id, translations },
													index
												) => (
													<IonSelectOption
														key={id}
														value={index}
													>
														{renderMultilingual(
															translations
														)}
													</IonSelectOption>
												)
											)}
										</StyledSelect>
									</SelectWrapper>
									<SelectWrapper lines="none">
										<StyledSelect
											interface="popover"
											interfaceOptions={{
												animated: false
											}}
											value={selectedLocaleIndex}
											onIonChange={handleLocaleChange}
										>
											{locations.length &&
												locations[
													selectedLocationIndex
												].locales.map(
													(
														{ id, translations },
														index
													) => (
														<IonSelectOption
															key={id}
															value={index}
														>
															{renderMultilingual(
																translations
															)}
														</IonSelectOption>
													)
												)}
										</StyledSelect>
									</SelectWrapper>
								</Section>
								<IonLabel>{t('Login.label_phone')}</IonLabel>
								<InputWrapper lines="none" error={error}>
									<IonInput
										type="tel"
										autofocus
										ref={phoneInputRef}
										clearInput
										value={phone}
										onIonChange={handlePhoneChange}
										onKeyPress={handleKeyPress}
										color={error ? 'danger' : undefined}
										id="login-input-phone"
										className="gtm-input-phone"
									/>
								</InputWrapper>
								{error && (
									<InputError>
										{t(error, '') || error}
									</InputError>
								)}
								<StyledButton
									expand="block"
									disabled={!phone || loading}
									onClick={handleTelegramClick}
									id="login-button-telegram"
									className="gtm-btn-login-telegram"
								>
									{/* TODO: add loading state to Button component */}
									{loading ? (
										<IonSpinner />
									) : (
										t('Login.button_login_telegram')
									)}
								</StyledButton>
								{REACT_APP_ENABLE_SMS_LOGIN === 'true' && (
									<StyledButton
										expand="block"
										disabled={!phone || loading}
										onClick={handleSmsClick}
										className="gtm-btn-login-sms"
									>
										{loading ? (
											<IonSpinner />
										) : (
											t('Login.button_login_sms')
										)}
									</StyledButton>
								)}
							</form>
							<Tips>
								<span>
									{t('Login.label_not_registered_telegram')}{' '}
								</span>
								<a
									href={REACT_APP_TELEGRAM_BOT_URL}
									target="_blank"
									rel="noopener noreferrer"
									className="gtm-link-register-telegram"
								>
									{t('Login.label_register_here')}
								</a>
								.
							</Tips>
							<Footer>
								<Tips>
									<Trans i18nKey="Login.label_terms_and_privacy">
										By logging in, I agree to
										Lalamove&apos;s{' '}
										<a
											href={t('URL.terms_and_conditions')}
											target="_blank"
											rel="noopener noreferrer"
											className="gtm-link-tnc"
										>
											Terms &amp; Conditions
										</a>{' '}
										and{' '}
										<a
											href={t('URL.privacy_policy')}
											target="_blank"
											rel="noopener noreferrer"
											className="gtm-link-privacy"
										>
											Privacy Policy
										</a>
										.
									</Trans>
								</Tips>
							</Footer>
						</>
					)}
				</Container>
			</IonContent>
		</IonPage>
	)
}

export default withRouter(Login)
