import React, { useEffect, useReducer, useState } from 'react'
import { connect } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import {
	IonContent,
	IonPage,
	IonSpinner,
	useIonViewWillEnter,
	useIonViewWillLeave
} from '@ionic/react'
import styled from 'styled-components'
import moment from 'moment'
import Header from 'components/Header'
import ErrorDisplay from 'components/ErrorDisplay'
import ShiftCard from 'components/ShiftCard'
import Dialog from 'components/Form/Dialog'
import SummaryCard from 'components/SummaryCard'
import {
	fetchError,
	otherError,
	dismissError,
	makeErrorSelector
} from 'redux/error'
import {
	validateUser,
	clearSummaryData,
	getLocations,
	createShift,
	fetchShiftByLocationAndDate,
	fetchRoute,
	updateShift,
	saveShift,
	saveAllShifts,
	readAllShifts,
	getTranslationValue,
	noop
} from 'utils/helpers'
import log from 'utils/log'
import storage, { LAST_PAGE, LOCATION } from 'utils/storage'

const REFRESH_INTERVAL = 10

const SHIFT_STATUS = {
	INIT: 'INIT',
	WITHDRAWN: 'WITHDRAWN',
	PENDING: 'PENDING',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED'
}
const { INIT, WITHDRAWN, PENDING, APPROVED, REJECTED } = SHIFT_STATUS

const StyledContent = styled(IonContent)`
	--ion-background-color: #f8f9fc;

	h6 {
		margin: 1em 2em -1em 8px;
	}
`

let shiftTimer // for auto-refresh interval

const Home = ({
	translations,
	icon,
	error,
	fetchError,
	otherError,
	dismissError
}) => {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [dialogType, setDialogType] = useState('') // apply / signOff
	const [shift, setShift] = useState({ today: null, tomorrow: null })
	const [cutoffTime, setCutoffTime] = useState('24:00')
	const [assignByTime, setAssignByTime] = useState('24:00')
	const [confirmDisplayOffset, setConfirmDisplayOffset] = useState(60)

	// when apply shift button is clicked
	const handleApplyClick = () => {
		setDialogType('apply')
		setIsDialogOpen(true)
	}

	// when sign off (withdraw) button is clicked
	const handleSignOffClick = () => {
		setDialogType('signOff')
		setIsDialogOpen(true)
	}

	// the default card state
	const defaultState = {
		status: INIT,
		image: 'courierbox.png',
		// TODO: need to deal with live translations..
		text: (
			<Trans i18nKey="Shift.text_apply">
				Apply to a shift for <b>tomorrow</b> and get the chance to{' '}
				<b>earn more</b>!
			</Trans>
		),
		buttonAction: handleApplyClick,
		buttonLabel: 'Shift.button_apply',
		buttonClass: 'gtm-btn-shift-apply',
		details: [
			{
				label: 'Shift.label_cutoff_time',
				value: `${cutoffTime} ${t('Datetime.today')}`
			}
		]
	}

	// initial cards state
	const initialState = {
		today: null,
		tomorrow: defaultState
	}

	// cards state reducer
	// decides what to render depending on shift status
	function reducer(state, action) {
		const { id, day, status, time, address, lat, lng, route } = action
		switch (status) {
			case INIT:
			case WITHDRAWN: {
				return {
					...state,
					// init UI state for today: render nothing
					// init UI state for tomorrow: render the default apply card
					[day]: day === 'today' ? null : defaultState
				}
			}
			case PENDING: {
				return {
					...state,
					[day]: {
						status,
						image: 'success.png',
						text: t('Shift.text_pending', { time: assignByTime }),
						buttonAction: handleSignOffClick,
						buttonLabel: 'Shift.button_sign_off',
						buttonClass: 'gtm-btn-shift-signoff',
						details: [
							{
								label: 'Shift.label_cutoff_time',
								value: `${cutoffTime} ${t('Datetime.today')}`
							}
						]
					}
				}
			}
			case APPROVED: {
				let addressValue = address
				if (lat && lng)
					addressValue = (
						<a
							href={`https://maps.google.com?q=${lat},${lng}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{address}
						</a>
					)
				return {
					...state,
					[day]: {
						status,
						image: 'tada.png',
						text: t('Shift.text_approved'),
						details: [
							{
								label: 'Shift.label_pickup_time',
								value: moment(time).format('HH:mm')
							},
							{
								label: 'Shift.label_pickup_point',
								value: addressValue
							},
							{ label: 'Shift.label_route', value: route },
							{ label: 'Shift.label_shift_id', value: id }
						]
					}
				}
			}
			case REJECTED: {
				return {
					...state,
					[day]: {
						status,
						image: 'norecord.svg',
						text: t('Shift.text_rejected'),
						details: []
					}
				}
			}
			default:
				return state
		}
	}

	const [state, dispatch] = useReducer(reducer, initialState)

	// things to do when user navigate into this page
	useIonViewWillEnter(() => {
		clearSummaryData() // clear summary data of the previous day, if any
		storage.setItem(LAST_PAGE, window.location.pathname) // set this as the last visited page (for redirect after login)
		validateUser() // force re-login if needed

		// start refresh interval:
		if (!shiftTimer) {
			shiftTimer = setInterval(() => {
				refreshAllShifts()
			}, REFRESH_INTERVAL * 1000)
		}
	})

	// things to do when user navigate away from this page
	useIonViewWillLeave(() => {
		// stop refresh interval:
		// (can't do on componentWillUnmount, because Ionic doesn't unmount pages)
		clearInterval(shiftTimer)
		shiftTimer = undefined
	})

	// initializes shift data and cards state
	useEffect(() => {
		// get shift options from locations:
		const getShiftOptions = async () => {
			const location = storage.getItem(LOCATION)
			const locationsData = await getLocations()
			if (location && locationsData) {
				const {
					shiftCutoffTime: cutoffTime,
					shiftAssignByTime: assignByTime,
					shiftConfirmDisplayOffsetMinutes: confirmDisplayOffset
				} = locationsData.find(l => l.id === location).attributes || {}
				if (cutoffTime) setCutoffTime(cutoffTime)
				if (assignByTime) setAssignByTime(assignByTime)
				if (confirmDisplayOffset)
					setConfirmDisplayOffset(confirmDisplayOffset)
			}
		}

		const initCards = async () => {
			setLoading(true)
			await getShiftOptions()
			await refreshAllShifts()
			setLoading(false)

			const s = readAllShifts() // read from storage
			if (!s) return
			const now = moment()

			// TODO: simplify this logic..
			if (s.today && moment(s.today.date).isSame(now, 'd')) {
				// if we have today's data with correct date,
				// then everything is fine
			} else if (s.tomorrow && moment(s.tomorrow.date).isSame(now, 'd')) {
				// if we have tomorrow's data but the date is today,
				// then we need to reassign this data for today
				if (s.tomorrow.status === APPROVED) {
					// reassign only if the status is APPROVED
					s.today = { ...s.tomorrow }
				} else {
					// otherwise, we don't have data for today
					s.today = null
				}
				s.tomorrow = null
			} else {
				// if today's data is outdated and tomorrow's data is not for today,
				// then we don't have data for today
				s.today = null
			}

			if (s.tomorrow) {
				if (moment(s.tomorrow.date).isSame(now.add(1, 'd'), 'd')) {
					// if we have tomorrow's data with correct date,
					// then everything is fine
				} else {
					// otherwise, we don't have data for tomorrow
					s.tomorrow = null
				}
			}
			setShift(s) // set new data to state
			saveAllShifts(s) // save new data to storage
			if (s.today) {
				// if we have today's data, update card
				dispatch({ day: 'today', ...s.today })
			}
			if (s.tomorrow) {
				// if we have tomorrow's data, update card
				dispatch({ day: 'tomorrow', ...s.tomorrow })
			}
		}
		initCards()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// get date portion of datetime string
	const stripDate = date => date.split('T')[0]

	const fetchShiftByDate = async dateStr => {
		try {
			const { data } = await fetchShiftByLocationAndDate(dateStr)
			if (!data.length) return []
			const { id, date, status, deliveryBatch } = data[0]
			let time = ''
			let address = ''
			let route = ''
			let latitude = ''
			let longitude = ''
			if (deliveryBatch && deliveryBatch.length) {
				// if has deliveryBatch
				const { startAt, routeId } = deliveryBatch[0]
				// fetch route by ID (to get pickup-point address)
				const response = await fetchRoute(routeId)
				const { text, lat, lng } = response.data.pickupPoint[0].address
				time = startAt
				address = text
				latitude = lat
				longitude = lng
				route = routeId
			}
			return [
				{
					id,
					date: stripDate(date),
					status,
					time,
					address,
					lat: latitude,
					lng: longitude,
					route
				}
			]
		} catch (err) {
			log.error('fetchShiftByDate failed', { category: 'API' }, err.stack)
			// showError(err)
		}
	}

	const updateShiftState = (day, data) => {
		// update UI state:
		dispatch({ day, ...(data.length ? data[0] : { status: INIT }) })
		const updated = data.length ? data[0] : null
		// update data state:
		setShift(shift => ({ ...shift, [day]: updated }))
		// save data:
		saveShift(day, updated)
	}

	const refreshAllShifts = async () => {
		const dateToday = moment().format('YYYY-MM-DD')
		const dateTomorrow = moment().add(1, 'd').format('YYYY-MM-DD')
		const shiftToday = await fetchShiftByDate(dateToday)
		const shiftTomorrow = await fetchShiftByDate(dateTomorrow)
		// update UI state, update shift data in state and storage
		if (shiftToday) updateShiftState('today', shiftToday)
		if (shiftTomorrow) updateShiftState('tomorrow', shiftTomorrow)
	}

	// apply for a shift
	const applyShift = async () => {
		setSubmitting(true)
		try {
			let data
			if (shift.tomorrow) {
				// PATCH request if shift data for tomorrow already exist (WITHDRAWN)
				const res = await updateShift(shift.tomorrow.id, PENDING)
				data = res.data
			} else {
				// POST request otherwise
				const res = await createShift()
				data = res.data[0]
			}
			const { id, date, status } = data
			// update card state, update shift data in state and storage
			dispatch({ day: 'tomorrow', status, time: cutoffTime })
			setShift({
				...shift,
				tomorrow: { id, date: stripDate(date), status }
			})
			saveShift('tomorrow', { id, date: stripDate(date), status })
		} catch (error) {
			log.error('applyShift failed', { category: 'API' }, error.stack)
			showError(error)
			// TODO: prettier error handling
		}
		setSubmitting(false)
	}

	// sign off (withdraw) from a shift
	const signOffShift = async () => {
		setSubmitting(true)
		try {
			// PATCH request
			const { data } = await updateShift(shift.tomorrow.id, WITHDRAWN)
			const { id, date, status } = data
			// update card state, update shift data in state and storage
			dispatch({ day: 'tomorrow', status, time: cutoffTime })
			setShift({
				...shift,
				tomorrow: { id, date: stripDate(date), status }
			})
			saveShift('tomorrow', { id, date: stripDate(date), status })
		} catch (error) {
			log.error('signOffShift failed', { category: 'API' }, error.stack)
			showError(error)
			// TODO: prettier error handling
		}
		setSubmitting(false)
	}

	// closes dialog
	const closeDialog = () => {
		setIsDialogOpen(false)
	}

	// props for apply shift dialog
	const applyDialogProps = {
		header: 'Shift.message_confirm_availability',
		buttonNoText: 'Shift.button_later',
		buttonYesText: 'Shift.button_confirm',
		onNo: closeDialog,
		onYes: applyShift
	}

	// props for sign off (withdraw) shift dialog
	const signOffDialogProps = {
		header: 'Shift.message_confirm_signoff',
		buttonNoText: 'Shift.button_confirm_signoff',
		buttonYesText: 'Shift.button_cancel_signoff',
		onNo: signOffShift,
		onYes: closeDialog
	}

	// show error
	const showError = error => {
		if (error.response) {
			fetchError(error)
		} else {
			otherError({ message: error.toString() })
		}
	}

	// dismiss error
	const handleErrorDismissed = () => {
		if (error) {
			dismissError(error.errorName)
		}
	}

	// check if current time is after cutoff time:
	const splitTime = cutoffTime.split(/:/)
	const cutoffMoment = moment()
		.hours(parseInt(splitTime[0]))
		.minutes(parseInt(splitTime[1]))
		.seconds(0)
		.milliseconds(0)
	const afterCutoff = moment() > cutoffMoment

	const { today, tomorrow } = state
	// check if today's order should be hidden (confirmDisplayOffset minutes after pickup time):
	const { time } = shift.today || {}
	const isTodayShown =
		today && time && moment() < moment(time).add(confirmDisplayOffset, 'm')
	return (
		<IonPage id="formpage">
			{loading ? (
				<IonSpinner style={{ margin: 'auto' }} />
			) : (
				<>
					<ErrorDisplay
						isOpen={!!error}
						blocking={false}
						requestId={error && error.requestId}
						message={error && error.message}
						onDismiss={handleErrorDismissed}
					/>
					<Header
						title={getTranslationValue(
							translations,
							t('Home.title')
						)}
						icon={icon}
					/>
					<StyledContent>
						<>
							<h6>{t('Summary.summary_overview_title')}</h6>
							<SummaryCard />
						</>
						{/* today's card is rendered if available and has not "expired" */}
						{isTodayShown && (
							<>
								<h6>{t('Shift.heading_order_today')}</h6>
								<ShiftCard
									cardId="shift-card-today"
									{...today}
								/>
							</>
						)}
						{/* tomorrow's card is always available */}
						<>
							<h6>{t('Shift.heading_booking_tomorrow')}</h6>
							<ShiftCard
								cardId="shift-card-tomorrow"
								{...tomorrow}
								loading={submitting}
								disabled={afterCutoff}
							/>
						</>
					</StyledContent>
					<Dialog
						isOpen={isDialogOpen}
						onDismiss={closeDialog}
						dialogClass="custom-dialog"
						{...(dialogType === 'signOff'
							? signOffDialogProps
							: applyDialogProps)}
					/>
				</>
			)}
		</IonPage>
	)
}

Home.defaultProps = {
	translations: [],
	icon: '',
	error: null,
	fetchError: noop,
	otherError: noop,
	dismissError: noop
}

const mapStateToProps = state => ({
	error: makeErrorSelector(['FETCH', 'OTHER'])(state)
})

export default connect(mapStateToProps, {
	fetchError,
	otherError,
	dismissError
})(Home)
