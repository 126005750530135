import React from "react"
import { IonIcon, IonModal } from "@ionic/react"
import { close } from "ionicons/icons"
import styled from "styled-components"
import QRCode from "qrcode.react"
import Button from "components/Button"
import { monospaceFont } from "styles/fonts"
import { noop } from "utils/helpers"
import { deflateQRCodeData } from "utils/qrcode"

const QRContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
`

const QRContent = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto;

	canvas {
		margin: auto;
	}
`

const QRHeading = styled.h1`
	margin: 1em auto;

	span {
		font-family: ${monospaceFont};
		font-size: 26px;
		font-weight: bold;
	}
`

const QRInfo = styled.p`
	margin: 1em auto;
	font-family: ${monospaceFont};
	font-size: 22px;
`

const QRCloseButton = styled(Button)`
	position: absolute;
	top: calc(var(--ion-safe-area-top, 0) + 0.5em);
	right: 0.5em;
	--padding-start: 0.5em;
	--padding-end: 0.5em;
`

const QRCodeDisplay = ({
	isOpen,
	backdropDismiss,
	onClose,
	data,
	headingLabel,
	headingValue,
	id,
}) => {
	return (
		<IonModal
			isOpen={isOpen}
			backdropDismiss={backdropDismiss}
			animated={false}
		>
			<QRContainer onClick={backdropDismiss ? onClose : noop}>
				<QRCloseButton shape="round" onClick={onClose}>
					<IonIcon icon={close}></IonIcon>
				</QRCloseButton>
				<QRContent>
					<QRHeading>
						{headingLabel}
						<span>{headingValue}</span>
					</QRHeading>
					<QRCode value={deflateQRCodeData(data)} size={256} />
					<QRInfo>{`ID: ${id
						.substring(0, 7)
						.toUpperCase()
						.replace(
							/^([A-Z0-9]{3})([A-Z0-9]{3})([A-Z0-9]{1})$/,
							"$1 $2 $3"
						)}`}</QRInfo>
				</QRContent>
			</QRContainer>
		</IonModal>
	)
}

QRCodeDisplay.defaultProps = {
	isOpen: false,
	backdropDismiss: false,
	onClose: noop,
	data: null,
	headingLabel: "",
	headingValue: "",
	id: "",
}

export default QRCodeDisplay
