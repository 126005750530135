import React, { useState, useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonFooter,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonModal,
	IonPage,
	IonPopover,
	IonRadioGroup,
	IonRadio,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
	useIonViewWillEnter,
	useIonViewWillLeave,
	IonReorderGroup
} from '@ionic/react'
import {
	arrowBack,
	barcode,
	call,
	checkmarkCircle,
	close,
	person,
	pin,
	radioButtonOff,
	radioButtonOn,
	search,
	time,
	trash,
	paper,
	funnel,
	swap
} from 'ionicons/icons'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Button from 'components/Button'
import { StyledContent } from 'components/Common'
import Barcode from 'components/Form/Barcode'
import Heading from 'components/Form/Heading'
import Header from 'components/Header'
import { FIELD_TYPE } from 'components/Form/constants'
import Dialog from 'components/Form/Dialog'
import MessageToast from 'components/Form/MessageToast'
import { submit, saveSummary, trySubmitQueue } from 'containers/ConnectedForm'
import { fetchError } from 'redux/error'
import { DROP_OFF_FAILURE } from 'redux/form'
import {
	RESET,
	initState as initMessageState,
	updateMessage,
	getMessage
} from 'redux/message'
import { getMeta } from 'redux/meta'
import {
	initSummary,
	getSummaryList,
	updateSummaryItemStatus,
	updateSummaryItem
} from 'redux/summary'
import {
	dark,
	llmPrimary,
	llmSuccess,
	llmWarning,
	medium,
	silver,
	white
} from 'styles/colors'
import { monospaceFont } from 'styles/fonts'
import { PARCEL_STATUS, FORM_TAG_STATUS_MAP, COLOR_MAP } from 'utils/constants'
import {
	DEFAULT_LOCALE,
	clearSummaryData,
	findFormat,
	formatDisplay,
	getCrossdock,
	getSummaryQuickActions,
	setLocation,
	noop,
	validateUser,
	getTranslationValue,
	updateCrossDockItemsInSummary,
	readSummaryData,
	saveSummaryData,
	getParcelsInfo,
	readSummarySortOrder,
	saveSummarySortOrder,
	FILTERS,
	CROSSDOCK_STATUS_GROUP,
	getSummaryParcelStatusTabKey
} from 'utils/helpers'
import { parseCrossDockQRCodeData } from 'utils/qrcode'
import storage, {
	LAST_PAGE,
	LOCATION,
	LOCATIONS,
	SUBMIT_ATTEMPTS,
	DISPLAY_FORMATS,
	CROSSDOCKS,
	FORM_MODELS
} from 'utils/storage'
import SummarySection from './containers/SummarySection'
import ParcelRecordItem from '../components/Summary/ParcelRecordItem'

const {
	REACT_APP_ENABLE_LLMP_289_CROSSDOCK,
	REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2,
	REACT_APP_ENABLE_LLMP_519_SUMMARY_SCAN_SEARCH,
	REACT_APP_ENABLE_MANUAL_RETRY
} = process.env
const ENABLE_CROSSDOCK = REACT_APP_ENABLE_LLMP_289_CROSSDOCK === 'true'
const CD_STATUS_UPDATE_INTERVAL = 10 // seconds

const {
	AWAITS_DRIVER,
	AWAITS_PICKUP,
	CANCELED,
	IN_PROGRESS,
	RESCHEDULED,
	DELIVERED,
	RETURNED,
	FAILED,
	CROSSDOCKED,
	NOT_CROSSDOCKED,
	PENDING,
	REJECTED,
	APPROVED,
	IN_THE_VEHICLE_CANCELED
} = PARCEL_STATUS

const CD_STATUS = [PENDING, REJECTED, APPROVED]
const CD_STATUS_MAP = {
	PENDING,
	REJECTED: NOT_CROSSDOCKED,
	APPROVED: CROSSDOCKED
}

const { BARCODE } = FIELD_TYPE

// SUMMARY'S STYLED COMPONENTS:

const ListCard = styled(IonCard)`
	padding: 0.5em 0;
	margin: 0 1em;
	border: 1px solid ${({ active }) => (active ? llmPrimary : silver)};
	border-radius: 0;
	background-color: ${white};
	ion-card-header {
		padding-top: 0.5em;
		border-bottom: 1px solid
			${({ active }) => (active ? llmPrimary : silver)};
	}
	ion-card-title {
		font-size: 16px;
		text-transform: uppercase;
		span:last-child {
			float: right;
			font-size: 14px;
			font-weight: bold;
			ion-icon {
				vertical-align: middle;
				font-size: 20px;
				color: ${({ active }) => (active ? llmPrimary : silver)};
			}
		}
	}
	ion-card-content {
		padding: 0 1em;
	}
`

const ParcelRecordsContainer = styled(ListCard)`
	padding: 0;
	background-color: rgb(248, 249, 252);
	border: 0;
	box-shadow: none;
`

const ParcelReorderGroup = styled(IonReorderGroup)`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export const ListItem = styled(IonItem)`
	--background: ${white};
	img {
		width: 24px;
		margin-right: 0.5em;
	}
`

// list title
const Title = styled.span`
	font-size: 18px;
`

// delete all button
const DeleteAllButton = styled(IonButton)`
	margin: 0 16px;
`

export const DeleteButton = styled(IonButton)`
	margin: 0;
	font-size: 16px;

	--padding-start: 0.5em;
	--padding-end: 0;
`

// status badge
export const Status = styled.span`
	min-width: 9em;
	border: 1px solid ${({ color }) => color};
	border-radius: 0;
	padding: 0.4em 0;
	text-align: center;
	color: ${({ active, color }) => (active ? white : color)};
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	background-color: ${({ active, color }) => (active ? color : white)};
	filter: ${({ disabled }) => (disabled ? 'grayscale(60%)' : 'none')};
`

// item text (parcel ID)
export const Text = styled(IonLabel)`
	margin: 0 0.5em 0 0;

	&& {
		white-space: normal;
		font-family: ${monospaceFont};
		font-size: ${({ large }) => (large ? 24 : 20)}px;
		font-weight: ${({ large }) => (large ? 'bold' : 'normal')};
	}
`

export const MutedText = styled.span`
	color: ${medium};
`

export const HighlightedText = styled.strong`
	color: ${dark};
`

export const SubText = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: ${medium};
`

const Legend = styled.div`
	margin: 1em;
	color: ${llmWarning};
	display: flex;
	align-items: center;
	gap: 4px;
`

// empty state for empty list
const EmptyState = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`

const SearchBar = styled.div`
	display: flex;
	align-items: center;
	> ion-item {
		flex: 1;
	}
	> ion-button {
		width: 38px;
		height: 38px;
		margin: 0 1em 0 0;
		--padding-start: 0;
		--padding-end: 0;
		> ion-icon {
			width: 32px;
			height: 32px;
		}
	}
`

const InputWrapper = styled(IonItem)`
	margin: 1em;
	border: 1px solid silver;
	border-radius: 0;

	--min-height: 38px;
	--padding-start: 0.5em;
	--inner-padding-end: 0.25em;
	--background: ${white};

	ion-input {
		margin: 0 0.2em;
	}
`

const SearchList = styled.ul`
	padding-left: 0;
	margin: 0;
	> li {
		list-style: none;
		&:before {
			content: '';
			display: inline-block;
			height: 24px;
			width: 24px;
			background-image: url('assets/icon/parcel.svg');
		}
		> span {
			margin-left: 0.5em;
			vertical-align: top;
			font-family: ${monospaceFont};
			font-size: 22px;
			word-break: break-all;
		}
	}
`

const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1em;
`

const Filters = styled.div`
	margin: 1em;

	span {
		display: inline-block;
		margin: 0 0.5em 0.5em 0;
	}
`

// DETAILS' STYLED COMPONENTS:

const StopCardContainer = styled.div`
	position: relative;
	margin-left: 1.5em;
	padding-left: 0.5em;
	padding-bottom: 1em;
	border-left-color: ${({ disabled }) => (disabled ? medium : llmPrimary)};
	border-left-style: solid;
	border-left-width: ${({ index, length }) =>
		index === 0 && length > 1 ? '2px' : '0'};
`

const TimelineIconBg = styled.span`
	position: absolute;
	top: -0.25em;
	left: -0.75em;
	width: 20px;
	height: 30px;
	border-radius: 50%;
	background-color: #f8f9fc;
`

const TimelineIcon = styled(IonIcon)`
	position: absolute;
	top: -0.1em;
	left: -0 ${({ index }) => (index === 0 ? '5' : '4')}em;
	width: 28px;
	height: 28px;
	color: ${({ disabled }) => (disabled ? medium : llmPrimary)};
`

const DeliveryInfo = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5em 0;
	color: ${dark};
	ion-icon {
		min-width: 32px;
		margin-right: 0.5em;
		color: ${({ active }) => (active ? llmPrimary : silver)};
	}
	a {
		color: ${dark};
	}
`

const labelWrapperStyle = {
	marginRight: '0.25em',
	fontWeight: 'bold'
}

const changedLabelStyle = { color: llmSuccess }

const DeliveryInfoLabel = ({ label, changedText }) => (
	<span style={labelWrapperStyle}>
		<span>{label}</span>
		{changedText && (
			<span style={changedLabelStyle}> {`(${changedText})`}</span>
		)}
		:
	</span>
)

const LogList = styled.ul`
	position: relative;
	padding: 0;
	margin: 0;
	list-style: none;

	&:before {
		content: '';
		position: absolute;
		top: 1.5em;
		bottom: 1.5em;
		left: 9px;
		border-left: 2px dotted ${llmPrimary};
	}
`

const LogListItem = styled.li`
	position: relative;
	padding-left: 28px;
`

const LogItemContainer = styled.div`
	padding: 12px 0;
	line-height: 1.4;

	&:before {
		content: '✓';
		position: absolute;
		top: 50%;
		width: 20px;
		height: 20px;
		background-color: ${llmPrimary};
		color: ${white};
		font-size: 0.9em;
		font-weight: bold;
		line-height: 20px;
		text-align: center;
		border-radius: 50%;
		box-shadow: 0 0 0 2px ${white};
		transform: translate(-28px, -10px);
	}
`

const LogItemContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${dark};

	span {
		margin: 0 0.5em;
	}
	span:last-child {
		font-weight: bold;
	}
`

const MainButton = styled(Button)`
	margin: 0;
	--background: ${({ bgColor }) => bgColor || dark};
`

const QuickActionButton = styled(Button)`
	height: 4em;
	margin: 1em;
	white-space: normal;
	font-weight: normal;
	color: ${dark};
	--border-width: 2px;
	--border-style: solid;
	--border-color: ${llmPrimary};
	--border-radius: ${({ squared }) => (squared ? '0' : '0.5em')};
	--background: ${white};
	--background-hover: #f8f9fc;
	--background-focused: #f8f9fc;
	--color-focused: ${dark};
	--color-activated: ${dark};
`
const HeaderButton = styled(IonButton)`
	&&& {
		width: 36px;
		height: 36px;
		--padding-top: 8px;
		--padding-end: 8px;
		--padding-bottom: 8px;
		--padding-start: 8px;
	}
`

const IconReorder = styled(IonIcon)`
	transform: rotate(90deg);
`

const OfflineIcon = styled.img`
	&&& {
		width: 20px;
	}
`

export const highlightMatch = (text, searchString) => {
	if (!searchString) return ''
	const index = text.indexOf(searchString)
	if (index < 0) return <MutedText>{text}</MutedText>
	const prefix = text.slice(0, index)
	const match = text.slice(index, index + searchString.length)
	const suffix = text.slice(index + searchString.length)
	return (
		<>
			<MutedText>{prefix}</MutedText>
			<HighlightedText>{match}</HighlightedText>
			<MutedText>{suffix}</MutedText>
		</>
	)
}

let displayFormats = []
let tempBarcodes = []

let cdsTimer
let eTags = {}

// main Summary component
const Summary = ({
	meta,
	list,
	message,
	initSummary,
	updateMessage,
	updateSummaryItemStatus,
	updateSummaryItem,
	fetchError,
	translations,
	icon
}) => {
	const { i18n, t } = useTranslation()
	const [tabKey, setTabKey] = useState('IN_THE_VEHICLE')
	const [filter, setFilter] = useState('')
	// confirmation dialog states
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [dialogMessage, setDialogMessage] = useState('')
	const [dialogObj, setDialogObj] = useState(null) // parcel object relevant to dialog { id, format }
	const [showModal, setShowModal] = useState(false)
	const [quickActions, setQuickActions] = useState([])
	const [availableActions, setAvailableActions] = useState([])
	const [parcel, setParcel] = useState({})
	const [isQuickActionDialogOpen, setIsQuickActionDialogOpen] =
		useState(false)
	const [quickAction, setQuickAction] = useState({})
	const [showQuickActions, setShowQuickActions] = useState(false)
	const [detailTabKey, setDetailTabKey] = useState('STOPS')

	const [crossDocks, setCrossDocks] = useState([])
	const [total, setTotal] = useState(0)

	const [clients, setClients] = useState([])
	// const [loadingOrderIds, setLoadingOrderIds] = useState(false)

	const [hasRetryQueue, setHasRetryQueue] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [searchBarcodes, setSearchBarcodes] = useState([])
	const [isShowSearchBar, setIsShowSearchBar] = useState(false)
	const [isShowMileFilter, setIsShowMileFilter] = useState(false)
	const [mileFilter, setMileFilter] = useState('all')
	const [isScannerOpen, setIsScannerOpen] = useState(false)
	const [attributes, setAttributes] = useState({})
	const [isToggleReorder, setToggleReorder] = useState(false)

	// ref to details content
	const contentRef = useRef(null)
	useEffect(() => {
		trySubmitQueue(updateMessage, updateSummaryItemStatus, fetchError)

		const clientList = JSON.parse(storage.getItem('clients'))
		setClients(clientList)

		// find COMMON_BARCODE valueFormats attribute:
		let attrs = {}
		const forms = JSON.parse(storage.getItem(FORM_MODELS)) || {}
		if (!forms['COMMON']) return
		for (const form of forms['COMMON']) {
			const field = form.fields.find(
				field => field.id === 'COMMON_BARCODE'
			)
			if (field) {
				attrs = { ...field.attributes }
				if (!attrs.valueFormats) attrs.valueFormats = []
				// filter out formats of unknown clients:
				attrs.valueFormats = attrs.valueFormats.filter(
					obj =>
						!obj.clientId ||
						clientList.find(c => c.id === obj.clientId)
				)
				// convert all regexp string to RegExp object:
				attrs.valueFormats.forEach(obj => {
					obj.regexp = (obj.regexp || []).map(str => new RegExp(str))
				})
				break
			}
		}
		setAttributes(attrs)
		const interval = setInterval(async () => {
			await getParcelsInfo()
			populateSummary()
		}, 30000) // get update per 30s
		return () => clearInterval(interval)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		tempBarcodes = searchBarcodes
	}, [searchBarcodes])

	useEffect(() => {
		if (REACT_APP_ENABLE_LLMP_289_CROSSDOCK_PHASE2 === 'true') {
			if (crossDocks.some(c => !c.data.DOCKSTATUS)) {
				updateCrossdocks()
			} else {
				clearInterval(cdsTimer)
				eTags = {}
			}
		}
	}, [crossDocks]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		populateSummary()
	}, [list])

	const updateCrossdocks = () => {
		clearInterval(cdsTimer)
		cdsTimer = setInterval(async () => {
			let hasUpdate = false
			const cds = crossDocks.slice()
			for (const cd of cds) {
				if (cd.data.DOCKSTATUS) continue

				const { DOCKID } = cd.data
				const res = await getCrossdock(DOCKID, eTags[DOCKID])
				if (!res) continue

				const { status } = res.data
				if (res.headers.etag) {
					eTags[DOCKID] = res.headers.etag // eslint-disable-line require-atomic-updates
				}
				if (status !== 'REVIEWING') {
					cd.data.DOCKSTATUS = status
					updateCrossDockItemsInSummary(
						parseCrossDockQRCodeData(cd.data.BARCODE).map(
							br => br[0].value
						),
						status
					)
					hasUpdate = true
				}
			}
			if (hasUpdate) {
				setCrossDocks(cds)
				const json = JSON.parse(storage.getItem(CROSSDOCKS)) || {}
				json.list = cds
				storage.setItem(CROSSDOCKS, JSON.stringify(json))
				if (!cds.some(c => !c.data.DOCKSTATUS)) {
					clearInterval(cdsTimer)
					eTags = {} // eslint-disable-line require-atomic-updates
				}
			}
		}, CD_STATUS_UPDATE_INTERVAL * 1000)
	}

	const getQuickActions = async () => {
		const summaryQuickActions = await getSummaryQuickActions()
		setQuickActions(summaryQuickActions)
	}

	// populate the list from local storage
	const populateSummary = () => {
		const summary = readSummaryData()
		const parcels = summary.parcels || {}
		const orders = summary.orders || {}
		const addId = ([id, obj]) => ({ id, ...obj })
		const isNotDeleted = obj => !obj.deletedAt // filter out items that are marked as deleted..
		const list = Object.entries(parcels).map(addId)
		list.push(...Object.entries(orders).map(addId))
		// find displayFormat of each item by clientId:
		list.forEach(i => {
			i.format = findFormat(
				displayFormats
					.filter(f => f.clientId === i.clientId)
					.map(f => f.format),
				i.id
			)
		})
		let crossDockedIds = []
		crossDocks.forEach(cd => {
			if (cd.data.BARCODE) {
				const qrdata = parseCrossDockQRCodeData(cd.data.BARCODE)
				crossDockedIds = crossDockedIds.concat(
					qrdata.map(qr => qr[0].value)
				)
			}
		})
		let summaryOrderObj = {
			TO_PICK_UP: [],
			IN_THE_VEHICLE: [],
			OFFLOADED: []
		}
		const summarySortOrder = readSummarySortOrder()
		if (!summarySortOrder) {
			Object.keys(FILTERS).forEach(tab => {
				summaryOrderObj[tab] = list.filter(
					item => getSummaryParcelStatusTabKey(item) === tab
				)
			})
		} else {
			summaryOrderObj = summarySortOrder
			// filter out cross-docking delivery (stored in crossDocks list)
			summaryOrderObj.IN_THE_VEHICLE =
				summarySortOrder.IN_THE_VEHICLE.filter(item => {
					const latestLog = item.logs[item.logs.length - 1]
					// PENDING status only for cross-docking
					return latestLog.status !== PARCEL_STATUS.PENDING
				})
			// filter out not today end state delivery
			summaryOrderObj.OFFLOADED = summarySortOrder.OFFLOADED.filter(
				item => {
					const latestLog = item.logs[item.logs.length - 1]
					const lastUpdatedAt = new Date(latestLog.time)
					const isTodayDelivery =
						lastUpdatedAt.toDateString() ===
						new Date().toDateString()
					return isTodayDelivery
				}
			)
			// find new items in list
			list.forEach(item => {
				const deliveryItemTag = getSummaryParcelStatusTabKey(item)
				// TODO: Refactor for clear logic
				Object.keys(summarySortOrder).forEach(tab => {
					if (tab === deliveryItemTag && isNotDeleted(item)) {
						// check item is new or not
						const isNewItem =
							summarySortOrder[tab].filter(orderItem => {
								return item.id === orderItem.id
							}).length === 0
						const latestLog = item.logs[item.logs.length - 1]
						const isNotCrossDocking =
							latestLog.status !== PARCEL_STATUS.PENDING
						if (isNewItem && !item.pending && isNotCrossDocking) {
							// add new item to list top
							const newTabArray = new Array(
								...summarySortOrder[tab]
							)
							newTabArray.unshift(item)
							summaryOrderObj[tab] = newTabArray
						} else {
							const currentIndex = summaryOrderObj[tab].findIndex(
								currentItem => currentItem.id === item.id
							)
							if (!item.pending) {
								summaryOrderObj[tab][currentIndex] = item
							} else {
								let pendingItem =
									summaryOrderObj[tab][currentIndex]
								if (pendingItem) {
									pendingItem.pending = true
								}
								summaryOrderObj[tab][currentIndex] = pendingItem
							}

							// update item status in same tab type
						}
					} else {
						// remove delivery in not related tag list
						summaryOrderObj[tab] = summaryOrderObj[tab].filter(
							delivery => delivery.id !== item.id
						)
					}
				})
			})
		}
		saveSummarySortOrder(summaryOrderObj)
		const summaryTotal =
			summaryOrderObj['TO_PICK_UP'].length +
			summaryOrderObj['IN_THE_VEHICLE'].length +
			summaryOrderObj['OFFLOADED'].length
		setTotal(summaryTotal)
	}

	const populateCrossDocks = () => {
		const cds = JSON.parse(storage.getItem(CROSSDOCKS)) || {}
		setCrossDocks(cds.list || [])
	}

	useIonViewWillEnter(() => {
		clearSummaryData()
		setHasRetryQueue(!!storage.getItem(SUBMIT_ATTEMPTS))
		storage.setItem(LAST_PAGE, window.location.pathname)
		validateUser()
	})

	useIonViewDidEnter(async () => {
		displayFormats = JSON.parse(storage.getItem(DISPLAY_FORMATS)) || []
		await getParcelsInfo()
		if (ENABLE_CROSSDOCK) populateCrossDocks()
		getQuickActions()
		// reset tabs/filters/search to default:
		setTabKey('IN_THE_VEHICLE')
		setDetailTabKey('STOPS')
		setFilter('')
		setSearchString('')
		setSearchBarcodes([])
	})

	useIonViewWillLeave(() => {
		clearInterval(cdsTimer)
		eTags = {}
	})

	// on delete all ids
	const handleDeleteAll = () => {
		setDialogObj(null)
		setDialogMessage(t('Form.dialog_delete_all'))
		setIsDialogOpen(true)
	}

	// on delete id
	const handleDeleteItem = (id, format) => {
		setDialogObj({ id, format })
		setDialogMessage(t('Form.dialog_delete_item'))
		setIsDialogOpen(true)
	}

	// closes dialog
	const closeDialog = () => {
		setIsDialogOpen(false)
	}

	// confirm dialog
	const confirmDeleteDialog = () => {
		setIsDialogOpen(false)

		const summary = readSummaryData()
		const parcels = summary.parcels || {}
		const orders = summary.orders || {}
		const updatedAt = new Date().toISOString()

		// delete all
		if (!dialogObj) {
			saveSummaryData({ parcels: {}, orders: {}, updatedAt })
			initSummary([])
			storage.setItem(CROSSDOCKS, JSON.stringify({ list: [], updatedAt }))
			setCrossDocks([])
			saveSummarySortOrder()
			populateSummary()
			return
		}

		// delete specific ID
		// just mark items as deleted, as we need to prevent sync from re-adding this item:
		const { id } = dialogObj
		if (parcels[id]) parcels[id].deletedAt = updatedAt
		if (orders[id]) orders[id].deletedAt = updatedAt
		saveSummaryData({ ...summary, parcels, orders, updatedAt })
		const summarySortOrder = readSummarySortOrder()
		summarySortOrder[tabKey] = summarySortOrder[tabKey].filter(
			item => item.id !== id
		)
		saveSummarySortOrder(summarySortOrder)
		populateSummary()
	}

	let crossDockedIds = []
	crossDocks.forEach(cd => {
		if (cd.data.BARCODE) {
			const qrdata = parseCrossDockQRCodeData(cd.data.BARCODE)
			crossDockedIds = crossDockedIds.concat(
				qrdata.map(qr => qr[0].value)
			)
		}
	})

	const handleTabChange = e => {
		setTabKey(e.target.value)
		setFilter('')
	}

	const handleDetailTabChange = e => {
		setDetailTabKey(e.target.value)
	}

	const handleSearchStringChange = e => {
		setSearchString(e.target.value.toUpperCase())
		setSearchBarcodes([])
	}

	const handleScannerClick = () => {
		setSearchString('')
		setIsShowSearchBar(false)
		setIsScannerOpen(true)
	}

	const toggleSearchBar = () => {
		setIsShowSearchBar(!isShowSearchBar)
	}

	const toggleMileFilter = () => {
		setIsShowMileFilter(!isShowMileFilter)
	}

	const toggleReorder = () => {
		setToggleReorder(!isToggleReorder)
	}

	const handleAdd = barcode => {
		setSearchString('')
		setSearchBarcodes([...tempBarcodes, barcode])
	}
	// const handleDelete = barcodeValue => setSearchBarcodes(searchBarcodes.filter(b => b.value !== barcodeValue))

	const handleClose = () => {
		setIsScannerOpen(false)
	}

	const handleClear = () => {
		setSearchBarcodes([])
		setSearchString('')
	}
	let showCrossDockSection = false
	if (searchString) {
		showCrossDockSection = crossDockedIds.some(id =>
			id.includes(searchString)
		)
	} else {
		showCrossDockSection =
			tabKey !== 'TO_PICK_UP' && (!filter || filter === CROSSDOCKED)
	}

	const filterCrossDock = (cd, tab) => {
		if (searchString) {
			return cd.data.BARCODE.includes(searchString)
		}
		return (
			CROSSDOCK_STATUS_GROUP[tab].includes(cd.data.DOCKSTATUS) ||
			(tab === 'IN_THE_VEHICLE' && !cd.data.DOCKSTATUS)
		)
	}
	const getCrossDockTabList = tab =>
		crossDocks.slice().filter(item => filterCrossDock(item, tab))
	const renderedCrossDockItems = getCrossDockTabList(tabKey) || []

	// count of each status type:
	const count = {
		[AWAITS_DRIVER]: 0,
		[AWAITS_PICKUP]: 0,
		[CANCELED]: 0,
		[IN_PROGRESS]: 0,
		[IN_THE_VEHICLE_CANCELED]: 0,
		[RESCHEDULED]: 0,
		[FAILED]: 0,
		[DELIVERED]: 0,
		[RETURNED]: 0,
		[CROSSDOCKED]: renderedCrossDockItems.length,
		[PENDING]: 0,
		[REJECTED]: 0,
		[APPROVED]: 0
	}
	const summarySortOrder = readSummarySortOrder()
	if (summarySortOrder) {
		Object.keys(summarySortOrder).forEach(key => {
			summarySortOrder[key].forEach(item => {
				if (item.status === CANCELED) {
					const lastLog = (item.logs[item.logs.length - 1] || {})
						.status
					if (lastLog === IN_PROGRESS) {
						count[IN_THE_VEHICLE_CANCELED] += 1
						return
					}
				}
				count[item.status] += 1
			})
		})
	}

	const groupedCount = {
		TO_PICK_UP: summarySortOrder
			? summarySortOrder['TO_PICK_UP'].length
			: 0,
		IN_THE_VEHICLE: summarySortOrder
			? summarySortOrder['IN_THE_VEHICLE'].length +
			  getCrossDockTabList('IN_THE_VEHICLE').length
			: 0,
		OFFLOADED: summarySortOrder
			? summarySortOrder['OFFLOADED'].length +
			  getCrossDockTabList('OFFLOADED').length
			: 0
	}

	const renderFilter = (type, group) => {
		if (type === AWAITS_DRIVER) return
		let typeCount = count[type]
		if (type === CROSSDOCKED) {
			typeCount = renderedCrossDockItems.length
		}
		if (group === 'IN_THE_VEHICLE' && type === CANCELED) {
			typeCount = count[IN_THE_VEHICLE_CANCELED]
		}
		return (
			<Status
				key={type}
				active={type === filter}
				color={COLOR_MAP[type]}
				onClick={() =>
					!isToggleReorder && setFilter(f => (f !== type ? type : ''))
				}
				disabled={isToggleReorder}
				className={`gtm-btn-summary-filter-${type}`}
			>
				{t(`PARCEL_STATUS.${type}`)} ×{typeCount}
			</Status>
		)
	}

	const applyFilter = () => {
		if (!summarySortOrder) {
			return []
		}
		if (searchBarcodes.length > 0) {
			// search by scan is top priority, filter by parcel ID / order ID (exact match search):
			return summarySortOrder[tabKey].filter(
				item =>
					!CD_STATUS.includes(item.status) &&
					(searchBarcodes.some(b => b.value === item.id) ||
						(item.orderId &&
							searchBarcodes.some(b => b.value === item.orderId)))
			)
		} else if (searchString) {
			// search string is next priority, filter by parcel ID / order ID (partial search):
			return summarySortOrder[tabKey].filter(
				item =>
					!CD_STATUS.includes(item.status) &&
					(item.id.includes(searchString) ||
						(item.orderId && item.orderId.includes(searchString)))
			)
		} else if (filter) {
			// filter by individual status is next (e.g. FAILED, RESHEDULED, etc.):
			return summarySortOrder[tabKey].filter(
				item => item.status === filter
			)
		} else {
			// lastly, filter by status group (i.e. TO_PICK_UP, IN_THE_VEHICLE, OFFLOADED):
			if (tabKey === 'TO_PICK_UP') {
				return summarySortOrder[tabKey].filter(
					item =>
						FILTERS[tabKey].includes(item.status) &&
						item.logs[item.logs.length - 1].status !== IN_PROGRESS
				)
			}
			if (tabKey === 'IN_THE_VEHICLE') {
				return summarySortOrder[tabKey].filter(item => {
					if (
						item.status === CANCELED &&
						item.logs[item.logs.length - 1].status !== IN_PROGRESS
					) {
						return false
					}
					return FILTERS[tabKey].includes(item.status)
				})
			}
			return summarySortOrder[tabKey].filter(item =>
				FILTERS[tabKey].includes(item.status)
			)
		}
	}
	const renderedItems = applyFilter()

	const prepareModal = parcel => {
		setParcel(parcel)
		let actions = (quickActions[parcel.clientId] || []).filter(i =>
			i.availableFor.includes(parcel.status)
		)
		const parcels = [parcel.id]
		const locale = i18n.language
		actions = actions
			.map(i => {
				const items = i.summaryItemActions.map(action => {
					action.input.fields.forEach(f => {
						if (f.tag === BARCODE) {
							f.values = parcels
						}
					})
					const translation =
						action.translations.find(i => i.locale === locale) ||
						action.translations.find(
							i => i.locale === DEFAULT_LOCALE
						)
					action.translation = translation
					action.clientId = parcel.clientId
					return action
				})
				return items
			})
			.flat()
		setAvailableActions(actions)
		setDetailTabKey('STOPS')
		setShowModal(true)
	}

	// on select quick action
	const handleQuickAction = action => {
		setQuickAction(action)
		setDialogMessage(t('Summary.message_quick_action_confirmation'))
		setIsQuickActionDialogOpen(true)
	}

	const confirmQuickAction = () => {
		setIsQuickActionDialogOpen(false)
		submitQuickAction(quickAction.input)
		setShowQuickActions(false)
		setShowModal(false)
	}

	const renderHeaderButtons = ({ isShowMileFilter, value: mileFilter }) => (
		<IonButtons slot="end">
			<HeaderButton
				onClick={toggleReorder}
				disabled={filter.length !== 0}
			>
				<IconReorder
					slot="icon-only"
					color={isToggleReorder ? 'header_button_active' : 'light'}
					icon={swap}
				/>
			</HeaderButton>
			<>
				<IonPopover
					isOpen={isShowMileFilter}
					onDidDismiss={e => setIsShowMileFilter(false)}
				>
					<IonList>
						<IonRadioGroup value={mileFilter}>
							<IonItem>
								<IonLabel>ALL</IonLabel>
								<IonRadio
									slot="start"
									value="all"
									onIonSelect={({ detail }) =>
										setMileFilter(detail.value)
									}
								/>
							</IonItem>
							<IonItem>
								<IonLabel>FIRST_MILE</IonLabel>
								<IonRadio
									slot="start"
									value="FIRST_MILE"
									onIonSelect={({ detail }) =>
										setMileFilter(detail.value)
									}
								/>
							</IonItem>
							<IonItem>
								<IonLabel>LAST_MILE</IonLabel>
								<IonRadio
									slot="start"
									value="LAST_MILE"
									onIonSelect={({ detail }) =>
										setMileFilter(detail.value)
									}
								/>
							</IonItem>
						</IonRadioGroup>
					</IonList>
				</IonPopover>
				<HeaderButton onClick={toggleMileFilter}>
					<IonIcon
						slot="icon-only"
						color={
							mileFilter === 'all'
								? 'light'
								: 'header_button_active'
						}
						icon={funnel.ios}
					/>
				</HeaderButton>
			</>
			<HeaderButton onClick={toggleSearchBar}>
				<IonIcon
					slot="icon-only"
					color={
						searchString === '' ? 'light' : 'header_button_active'
					}
					icon={search}
				/>
			</HeaderButton>
		</IonButtons>
	)

	const submitQuickAction = async data => {
		const isRescheduled = data.fields.some(
			f => f.tag === 'REASONS_FAILURE' && f.values[0].startsWith('17')
		)
		data.submittedAt = new Date().toISOString()
		data.locationId = storage.getItem(LOCATION)
		data.clientId = quickAction.clientId
		data.fields.forEach(field => {
			let status
			if (data.formId.endsWith(DROP_OFF_FAILURE)) {
				status = isRescheduled ? RESCHEDULED : FAILED
			} else {
				status = DELIVERED
			}
			if (field.tag === 'BARCODE') {
				field.values.forEach(value => {
					updateSummaryItemStatus(value, status, false)
					updateSummaryItem(value, {
						logs: [
							...parcel.logs,
							{ status: RESCHEDULED, time: data.submittedAt }
						]
					})
				})
			}
		})
		await setLocation(meta)
		// eslint-disable-next-line require-atomic-updates
		quickAction.input = Object.assign(quickAction.input, {
			meta: { ...meta, submittedVia: 'QUICK_ACTION' }
		})
		saveSummary(quickAction.input, quickAction.clientId)
		await submit(data, updateMessage, updateSummaryItemStatus, fetchError)
	}

	const closeQuickAction = () => {
		setIsQuickActionDialogOpen(false)
	}

	function arrayMove(from, to, arrayList) {
		const item = arrayList.splice(from, 1)[0]
		arrayList.splice(to, 0, item)
		return arrayList
	}

	const onItemReorder = e => {
		const sortOrder = readSummarySortOrder()
		const sortedArray = arrayMove(
			e.detail.from,
			e.detail.to,
			sortOrder[tabKey]
		)
		sortOrder[tabKey] = sortedArray
		saveSummarySortOrder(sortOrder)
		e.detail.complete()
	}
	// note: requires feature flag: `REACT_APP_ENABLE_MANUAL_RETRY`
	const manualRetry = async (e, parcel) => {
		e.stopPropagation()
		await setLocation(meta)
		const data = {
			clientId: parcel.clientId,
			fields: [{ tag: BARCODE, values: [parcel.id] }],
			formId: `${
				parcel.form && parcel.form.startsWith('PICKUP')
					? parcel.clientId
					: 'COMMON'
			}_${parcel.form}`,
			locationId: storage.getItem(LOCATION),
			meta: { ...meta, submittedVia: 'MANUAL_RETRY' },
			submittedAt: new Date().toISOString()
		}
		await submit(data, updateMessage, updateSummaryItemStatus, fetchError)
	}

	const clientNames = clients.reduce((acc, client) => {
		const { id, translations } = client
		acc[id] = getTranslationValue(translations, id, 'name')
		return acc
	}, {})

	const renderAddress = () => {
		const addresses = parcel.address ? [...parcel.address] : []
		while (addresses.length < 2) {
			addresses.push({})
		}

		const canceled = parcel.status === CANCELED
		const shouldMarkAsChanged = key =>
			!canceled && parcel.changed && parcel.changed.includes(key)
		const active = i =>
			!canceled &&
			((i === 0 && parcel.status === AWAITS_PICKUP) ||
				(i === 1 &&
					parcel.status !== AWAITS_PICKUP &&
					![DELIVERED, APPROVED].includes(parcel.status)))
		const done = i =>
			(i === 0 && ![AWAITS_PICKUP, CANCELED].includes(parcel.status)) ||
			(i === 1 && [DELIVERED, APPROVED].includes(parcel.status))

		const locationInfoArray = JSON.parse(storage.getItem(LOCATIONS)) || []
		const locationInfo = locationInfoArray.filter(
			location => location.id === storage.getItem(LOCATION)
		)
		const phoneFormats =
			locationInfo[0] && locationInfo[0].phoneFormats.templateIntl
		return addresses.map((addr, i) => {
			const phoneNumber =
				addr.contacts && addr.contacts.length
					? phoneFormats && !addr.contacts[0].phone.startsWith('+')
						? phoneFormats.replace(
								'{{base}}',
								addr.contacts[0].phone
						  )
						: addr.contacts[0].phone
					: null
			return (
				<StopCardContainer
					key={i}
					index={i}
					length={addresses.length}
					disabled={canceled}
				>
					<ListCard active={active(i)}>
						<IonCardHeader>
							<IonCardTitle>
								<span>
									{t(
										`${
											i === 0 ? 'PICKUP' : 'DROP_OFF'
										}.title`
									)}
								</span>
								<span>
									<IonIcon
										icon={time}
										size="medium"
									></IonIcon>
									{i === 0 && parcel.pickupAt && (
										<span style={{ lineHeight: '20px' }}>
											{shouldMarkAsChanged(
												'pickupAt'
											) && (
												<span
													style={{
														...labelWrapperStyle,
														...changedLabelStyle
													}}
												>
													{t('Summary.label_changed')}
													:
												</span>
											)}
											<span>
												{moment(parcel.pickupAt).format(
													t('Datetime.time_display')
												)}
											</span>
										</span>
									)}
									{i === 1 && parcel.deliveryBy && (
										<span style={{ lineHeight: '20px' }}>
											{shouldMarkAsChanged(
												'deliveryBy'
											) && (
												<span
													style={{
														...labelWrapperStyle,
														...changedLabelStyle
													}}
												>
													{t('Summary.label_changed')}
													:
												</span>
											)}
											<span>
												{moment(
													parcel.deliveryBy
												).format(
													t('Datetime.time_display')
												)}
											</span>
										</span>
									)}
								</span>
							</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<DeliveryInfo active={active(i)}>
								<IonIcon icon={pin} size="large"></IonIcon>
								<div>
									<DeliveryInfoLabel
										label={t(
											`PORTAL.Place Order.table_header_${
												i === 0 ? 'pickup' : 'dropoff'
											}_address`
										)}
										changedText={
											shouldMarkAsChanged(`address${i}`)
												? t('Summary.label_changed')
												: ''
										}
									/>
									<span>{addr.text}</span>
								</div>
							</DeliveryInfo>
							<DeliveryInfo active={active(i)}>
								<IonIcon icon={person} size="large"></IonIcon>
								<div>
									<DeliveryInfoLabel
										label={t('Summary.label_contact_name')}
										changedText={
											shouldMarkAsChanged(`name${i}`)
												? t('Summary.label_changed')
												: ''
										}
									/>
									{addr.contacts && addr.contacts.length && (
										<span>{addr.contacts[0].name}</span>
									)}
								</div>
							</DeliveryInfo>
							<DeliveryInfo active={active(i)}>
								<IonIcon icon={call} size="large"></IonIcon>
								<div>
									<DeliveryInfoLabel
										label={t('Login.label_phone')}
										changedText={
											shouldMarkAsChanged(`phone${i}`)
												? t('Summary.label_changed')
												: ''
										}
									/>
									{addr.contacts && addr.contacts.length && (
										<span>
											<a href={`tel:${phoneNumber}`}>
												{phoneNumber}
											</a>
										</span>
									)}
								</div>
							</DeliveryInfo>
							<DeliveryInfo active={active(i)}>
								<IonIcon icon={paper} size="large"></IonIcon>
								<div>
									<DeliveryInfoLabel
										label={t('Summary.label_remarks')}
										changedText={
											shouldMarkAsChanged(`remarks`)
												? t('Summary.label_changed')
												: ''
										}
									/>
									<Trans>{parcel.remarks}</Trans>
									{/* { parcel.remarks && <span>{parcel.remarks}</span> } */}
								</div>
							</DeliveryInfo>
						</IonCardContent>
					</ListCard>
					<TimelineIconBg />
					<TimelineIcon
						icon={
							done(i)
								? checkmarkCircle
								: active(i)
								? radioButtonOn
								: radioButtonOff
						}
						size="large"
						index={i}
						disabled={canceled}
					></TimelineIcon>
				</StopCardContainer>
			)
		})
	}
	return (
		<IonPage id="formpage">
			<Header
				title={`${getTranslationValue(
					translations,
					t('Summary.title')
				)} ×${total}`}
			>
				{renderHeaderButtons({ isShowMileFilter, value: mileFilter })}
			</Header>
			{message.variant && (
				<MessageToast
					type={message.variant}
					message={t(message.content.key, message.content.data)}
					buttonText={t(
						message.dismissable
							? 'Common.button_close'
							: 'Form.button_retry_now'
					)}
					buttonClick={
						message.dismissable
							? () => updateMessage(RESET)
							: () =>
									trySubmitQueue(
										updateMessage,
										updateSummaryItemStatus,
										fetchError
									)
					}
					shouldShowButton={message.hasAction}
				/>
			)}
			<StyledContent>
				{!total && !crossDocks.length && (
					<EmptyState>
						<img
							src="assets/icon/norecord.svg"
							alt={t('Summary.label_empty')}
							width="200"
							height="200"
						/>
						<div>{t('Summary.label_empty')}</div>
					</EmptyState>
				)}
				{Boolean(total || crossDocks.length) && (
					<IonList>
						<IonListHeader style={{ display: 'none' }}>
							<Title style={{ visibility: 'hidden' }}>
								{t('Summary.label_parcels')} ×
								{total + crossDockedIds.length}
							</Title>
							<DeleteAllButton
								color="danger"
								size="small"
								onClick={handleDeleteAll}
							>
								<IonIcon icon={trash}></IonIcon>
								{t('Common.button_delete_all')}
							</DeleteAllButton>
						</IonListHeader>
						{isShowSearchBar && (
							<SearchBar>
								<InputWrapper lines="none" style={{ flex: 1 }}>
									<IonIcon icon={search}></IonIcon>
									<IonInput
										type="text"
										value={searchString}
										onIonChange={handleSearchStringChange}
										placeholder={t('Summary.label_search')}
										inputmode="NUMERIC"
										className="gtm-input-summary-search"
									></IonInput>
									{/* cannot use clearInput because it would be hidden onBlur */}
									{searchString && (
										<IonIcon
											icon={close}
											onClick={() => setSearchString('')}
										></IonIcon>
									)}
								</InputWrapper>

								{REACT_APP_ENABLE_LLMP_519_SUMMARY_SCAN_SEARCH ===
									'true' && (
									<Button
										color="dark"
										fill="clear"
										onClick={handleScannerClick}
										className="gtm-btn-summary-scan-search"
									>
										<IonIcon icon={barcode}></IonIcon>
									</Button>
								)}
							</SearchBar>
						)}
						{isScannerOpen && (
							<div style={{ padding: '0 1em' }}>
								<Barcode
									clients={clients}
									barcodes={searchBarcodes}
									onAdd={handleAdd}
									onClose={handleClose}
									label={t('Summary.heading_scan_to_search')}
									scanWindow={attributes.scanWindow}
									valueFormats={attributes.valueFormats}
									defaultInputMode={
										attributes.defaultInputMode
									}
									formGroup={'SUMMARY'}
									formTag={'SUMMARY'}
									fieldTag={'BARCODE'}
									fieldId={'SEARCH'}
									submitting={!isScannerOpen} // when `submitting` value changes to true, the scanner is stopped..
									forSummary // moved this as prop to avoid multiple checks inside component
								/>
							</div>
						)}

						{searchBarcodes.length > 0 && (
							<div style={{ padding: '0 1em' }}>
								<h6>{t('Summary.heading_scan_searched')}:</h6>
								<SearchList>
									{searchBarcodes.map(b => (
										<li key={b.value}>
											<span>{b.value}</span>
										</li>
									))}
								</SearchList>
							</div>
						)}

						{(searchString.length > 0 ||
							searchBarcodes.length > 0) && (
							<HeadingContainer>
								<Heading
									label={`${t(
										'Summary.heading_scan_results'
									)}:`}
								/>
								<Button size="small" onClick={handleClear}>
									{t('Common.button_clear')}
								</Button>
							</HeadingContainer>
						)}

						{!searchBarcodes.length && !searchString && (
							<>
								<IonSegment
									color="dark"
									value={tabKey}
									onIonChange={handleTabChange}
								>
									{Object.entries(groupedCount).map(
										([key, count]) => (
											<IonSegmentButton
												key={key}
												value={key}
												id={`${key}-button`}
												className={`gtm-btn-summary-tab-${key}`}
											>
												<div>
													{t(
														`Summary.label_${key.toLowerCase()}`
													)}
												</div>
												<div>×{count}</div>
											</IonSegmentButton>
										)
									)}
								</IonSegment>

								<Filters>
									{FILTERS[tabKey].map(f =>
										renderFilter(f, tabKey)
									)}
								</Filters>
							</>
						)}
						{renderedItems.length > 0 && (
							<ParcelRecordsContainer>
								<ParcelReorderGroup
									disabled={!isToggleReorder}
									onIonItemReorder={onItemReorder}
								>
									{/* sort parcels by ID value: */}
									{renderedItems
										.filter(parcel => {
											switch (mileFilter) {
												case 'all':
													return true
												default:
													return (
														parcel.deliveryStage ===
														mileFilter
													)
											}
										})
										.map((parcel, i) => (
											// This sliding component is used for delete action button
											<ParcelRecordItem
												key={parcel.id}
												parcel={parcel}
												handleEvents={{
													onClick: e => {
														e.stopPropagation()
														prepareModal(parcel)
													},
													onDelete: handleDeleteItem,
													onRetryUpload: manualRetry
												}}
												showPendingUpload={
													parcel.pending &&
													(hasRetryQueue ||
														REACT_APP_ENABLE_MANUAL_RETRY !==
															'true')
												}
												showRetryUpload={
													REACT_APP_ENABLE_MANUAL_RETRY ===
														'true' &&
													parcel.pending &&
													!hasRetryQueue
												}
											/>
										))}
								</ParcelReorderGroup>
							</ParcelRecordsContainer>
						)}
						{!renderedItems.length &&
							!renderedCrossDockItems.length &&
							(searchString || searchBarcodes.length > 0) && (
								<EmptyState>
									<img
										src="assets/icon/noresult.svg"
										alt="No match"
										width="200"
										height="200"
									/>
									<div>
										{t(
											`Summary.label_no_${
												searchBarcodes.length
													? 'scan_'
													: ''
											}match`,
											{ searchString }
										)}
									</div>
								</EmptyState>
							)}
						{renderedItems.some(item => item.pending) && (
							<Legend>
								<OfflineIcon src="assets/icon/offline.png" />
								{`${t('Form.message_upload_pending_short')}`}
							</Legend>
						)}
					</IonList>
				)}

				<br />

				{showCrossDockSection &&
					renderedCrossDockItems
						.reverse()
						.map(({ data, submittedAt }, i) => (
							<ListCard key={i} style={{ marginBottom: '1em' }}>
								<SummarySection
									data={data}
									submittedAt={submittedAt}
									onItemClick={id => {
										const summaryData = readSummaryData()
										return prepareModal({
											id: id,
											...summaryData.parcels[id]
										})
									}}
									onRemove={populateCrossDocks}
									displayFormats={displayFormats}
									searchString={searchString}
									clientNames={clientNames}
								/>
							</ListCard>
						))}

				<IonModal
					backdropDismiss={false}
					isOpen={showModal}
					animated={false}
				>
					<IonHeader>
						<IonToolbar color="dark">
							<IonButton
								slot="start"
								size="medium"
								color="dark"
								onClick={() => {
									if (showQuickActions)
										setShowQuickActions(false)
									else setShowModal(false)
								}}
								className="gtm-btn-summary-back"
							>
								<IonIcon icon={arrowBack} />
							</IonButton>
							<IonTitle color="light">
								{t(
									`Summary.label_${
										showQuickActions
											? 'quick_submission'
											: 'details'
									}`
								)}
							</IonTitle>
						</IonToolbar>
					</IonHeader>
					<StyledContent ref={contentRef}>
						<IonList>
							<ListCard>
								<ListItem lines="none">
									<Text large>
										{formatDisplay(
											parcel.id,
											parcel.format
										)}
										{parcel.id === parcel.orderId &&
											` (${t('Summary.label_order')})`}
									</Text>
									<Status
										active
										color={COLOR_MAP[parcel.status]}
									>
										{t(
											`PARCEL_STATUS.${
												CD_STATUS_MAP[parcel.status] ||
												parcel.status
											}`
										)}
									</Status>
								</ListItem>
							</ListCard>
						</IonList>

						{!showQuickActions && (
							<IonSegment
								color="dark"
								value={detailTabKey}
								onIonChange={handleDetailTabChange}
							>
								<IonSegmentButton
									value="STOPS"
									className="gtm-btn-summary-tab-STOPS"
								>
									{t('Summary.label_stops')}
								</IonSegmentButton>
								<IonSegmentButton
									value="LOGS"
									className="gtm-btn-summary-tab-LOGS"
								>
									{t('PORTAL.Deliveries.heading_logs')}
								</IonSegmentButton>
							</IonSegment>
						)}

						<br />

						{!showQuickActions &&
							detailTabKey === 'STOPS' &&
							renderAddress()}

						{!showQuickActions && detailTabKey === 'LOGS' && (
							<ListCard>
								<IonCardContent>
									<LogList>
										{parcel.logs &&
											parcel.logs
												.sort(
													(a, b) =>
														new Date(a.time) -
														new Date(b.time)
												)
												.map((log, i) => (
													<LogListItem key={i}>
														<LogItemContainer>
															<LogItemContent>
																<div>
																	<span>
																		{moment(
																			log.time
																		).format(
																			t(
																				'Datetime.date_display'
																			)
																		)}
																	</span>
																	<span>
																		{moment(
																			log.time
																		).format(
																			t(
																				'Datetime.time_display'
																			)
																		)}
																	</span>
																</div>
																<Status
																	active
																	color={
																		COLOR_MAP[
																			log
																				.status
																		]
																	}
																>
																	{t(
																		`PARCEL_STATUS.${
																			CD_STATUS_MAP[
																				log
																					.status
																			] ||
																			log.status
																		}`
																	)}
																</Status>
															</LogItemContent>
														</LogItemContainer>
													</LogListItem>
												))}
									</LogList>
								</IonCardContent>
							</ListCard>
						)}

						{showQuickActions &&
							availableActions.map((action, index) => (
								<QuickActionButton
									key={index}
									expand="block"
									size="large"
									bgColor={action.attributes.bgColor}
									onClick={() => handleQuickAction(action)}
									className="gtm-btn-summary-quick-action-item"
								>
									{action.translation.value}
								</QuickActionButton>
							))}
					</StyledContent>
					{!showQuickActions && availableActions.length > 0 && (
						<IonFooter>
							<IonToolbar>
								<MainButton
									expand="full"
									size="large"
									bgColor={llmPrimary}
									onClick={() => setShowQuickActions(true)}
									className="gtm-btn-summary-quick-action"
								>
									{t('Summary.label_quick_submission')}
								</MainButton>
							</IonToolbar>
						</IonFooter>
					)}
				</IonModal>
			</StyledContent>
			<Dialog
				isOpen={isDialogOpen}
				header={dialogMessage}
				message={
					dialogObj && formatDisplay(dialogObj.id, dialogObj.format)
				}
				onNo={closeDialog}
				onYes={confirmDeleteDialog}
				onDismiss={closeDialog}
				hasOptions
			/>
			<Dialog
				isOpen={isQuickActionDialogOpen}
				header={dialogMessage}
				onNo={closeQuickAction}
				onYes={confirmQuickAction}
				onDismiss={closeQuickAction}
				hasOptions
			/>
		</IonPage>
	)
}

Summary.defaultProps = {
	list: [],
	message: initMessageState,
	initSummary: noop,
	updateMessage: noop,
	updateSummaryItemStatus: noop,
	updateSummaryItem: noop,
	fetchError: noop,
	translations: [],
	icon: ''
}

const mapStateToProps = state => ({
	list: getSummaryList(state),
	message: getMessage(state),
	meta: getMeta(state)
})

export default connect(mapStateToProps, {
	initSummary,
	updateMessage,
	updateSummaryItemStatus,
	updateSummaryItem,
	fetchError
})(Summary)
