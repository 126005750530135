import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
	IonCard,
	IonCardContent,
	IonContent,
	IonPage,
	IonSpinner,
	useIonViewWillEnter,
} from "@ionic/react"
import styled from "styled-components"
import moment from "moment"
import Header from "components/Header"
import { dark, llmError, llmInfo, llmSuccess, white } from "styles/colors"
import {
	clearSummaryData,
	fetchRoute,
	fetchShiftsOfCurrentLocation,
	getTranslationValue,
	validateUser,
} from "utils/helpers"
import log from "utils/log"
import storage, { LAST_PAGE } from "utils/storage"

const COLOR_MAP = {
	WITHDRAWN: dark,
	PENDING: llmInfo,
	APPROVED: llmSuccess,
	REJECTED: llmError,
}

const StyledContent = styled(IonContent)`
	--ion-background-color: #f8f9fc;
	h6 {
		margin: 2em 1.5em 1em 1.5em;
	}
`

const Filters = styled.div`
	margin: 1em;
	span {
		display: inline-block;
		margin: 0 0.5em 0.5em 0;
	}
`

// status badge
export const Status = styled.span`
	min-width: 9em;
	border: 1px solid ${({ color }) => color};
	padding: 0.4em 0;
	text-align: center;
	color: ${({ active, color }) => (active ? white : color)};
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	background-color: ${({ active, color }) => (active ? color : white)};
`

const Row = styled.div`
	margin: 2em 1em;
	> p {
		margin: 0.5em;
	}
`

const StyledCard = styled(IonCard)`
	background-color: ${white};
`

const ShiftHistory = ({ translations, icon }) => {
	const { t } = useTranslation()
	const [filter, setFilter] = useState("APPROVED")
	const [loading, setLoading] = useState(false)
	const [shifts, setShifts] = useState([])
	const [routes, setRoutes] = useState({})

	// things to do when user navigate into this page
	useIonViewWillEnter(() => {
		clearSummaryData() // clear summary data of the previous day, if any
		storage.setItem(LAST_PAGE, window.location.pathname) // set this as the last visited page (for redirect after login)
		validateUser() // force re-login if needed
	})

	useEffect(() => {
		fetchShifts()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const fetchRoutes = async (list) => {
		const map = { ...routes }
		for (const item of list) {
			if (!item.deliveryBatch.length) continue
			try {
				const { routeId } = item.deliveryBatch[0]
				if (map[routeId]) continue
				const { data } = await fetchRoute(routeId)
				map[routeId] = data.pickupPoint[0].address.text
			} catch (err) {
				log.error("fetchRoutes failed", { category: "API" }, err.stack)
			}
		}
		setRoutes(map)
	}

	const fetchShifts = async () => {
		setLoading(true)
		const list = await fetchShiftsOfCurrentLocation()
		setShifts(list.sort((a, b) => new Date(b.date) - new Date(a.date)))
		setLoading(false)
		await fetchRoutes(list)
	}

	// count of each status type:
	const count = {
		WITHDRAWN: 0,
		PENDING: 0,
		APPROVED: 0,
		REJECTED: 0,
	}
	shifts.forEach((item) => {
		count[item.status] += 1
	})

	const renderFilter = (type) => {
		return (
			<Status
				active={type === filter}
				color={COLOR_MAP[type] || dark}
				onClick={() => setFilter((f) => (f !== type ? type : ""))}
			>
				{type} ×{count[type]}
			</Status>
		)
	}

	const renderCard = (shift) => {
		const { status, deliveryBatch } = shift
		if (status !== "APPROVED" || !deliveryBatch.length) {
			return <p>{shift.status}</p>
		}
		return (
			<>
				<p>
					<b>{t("Shift.label_pickup_point")}:</b>{" "}
					{routes[deliveryBatch[0].routeId] || t("PORTAL.Login.button_loading")}
				</p>
				<p>
					<b>{t("Shift.label_route")}:</b> {deliveryBatch[0].routeId}
				</p>
				<p>
					<b>{t("Shift.label_pickup_time")}:</b>{" "}
					{moment(deliveryBatch[0].startAt).format(t("Datetime.time_display"))}
				</p>
			</>
		)
	}

	const renderedShifts = filter
		? shifts.filter((item) => item.status === filter)
		: shifts

	return (
		<IonPage id="formpage">
			{loading ? (
				<IonSpinner style={{ margin: "auto" }} />
			) : (
				<>
					<Header
						title={getTranslationValue(translations, t("Home.title"))}
						icon={icon}
					/>
					<StyledContent>
						<h6>{t("Shift.heading_shift_history", { days: 15 })}:</h6>

						<Filters>
							{renderFilter("APPROVED")}
							{renderFilter("REJECTED")}
							{renderFilter("PENDING")}
							{renderFilter("WITHDRAWN")}
						</Filters>

						{renderedShifts.map((shift) => (
							<Row key={shift.id}>
								<p>
									{t("Shift.label_shift_id")} <b>{shift.id}</b> -{" "}
									{moment(shift.date).format(t("Datetime.date_display"))}
								</p>
								<StyledCard>
									<IonCardContent>{renderCard(shift)}</IonCardContent>
								</StyledCard>
							</Row>
						))}
					</StyledContent>
				</>
			)}
		</IonPage>
	)
}

ShiftHistory.defaultProps = {
	translations: [],
	icon: "",
}

export default ShiftHistory
