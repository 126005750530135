// This module will handle all app-wide configs

// 1. Action types:
export const SET_CAMERAS = 'SET_CAMERAS'
export const SELECT_CAMERA = 'SELECT_CAMERA'

// 2. Action creators:
export const setCameras = cameras => ({
	type: SET_CAMERAS,
	cameras
})

export const selectCamera = (camera, cameraLabel) => ({
	type: SELECT_CAMERA,
	camera,
	cameraLabel
})

// 3. Initial states:
const initState = {
	cameras: []
	// .. other configs in the future..
}

// 4. Selectors:
export const getCameras = state => {
	return state.config.cameras
}
export const getSelectedCamera = state => state.config.selectedCamera
export const getSelectedCameraLabel = state => state.config.selectedCameraLabel

// 5. Reducers:
export default function reducer(
	state = initState,
	{ type, cameras, camera, cameraLabel }
) {
	switch (type) {
		case SET_CAMERAS:
			return { ...state, cameras }
		case SELECT_CAMERA:
			return {
				...state,
				selectedCamera: camera,
				...(cameraLabel && { selectedCameraLabel: cameraLabel })
			}
		default:
			return state
	}
}
