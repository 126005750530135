import React from 'react'
import styled from 'styled-components'
import { IonCard, IonCardContent } from '@ionic/react'
import Button from 'components/Button'
import { llmPrimary, white } from 'styles/colors'
import SummaryTab from 'components/Summary/SummaryTab'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const StyledCard = styled(IonCard)`
	margin: 2em 0;
	padding: 0;
	background-color: ${white};
	text-align: center;
`

const SummaryCard = () => {
	const { t } = useTranslation()
	return (
		<StyledCard>
			<IonCardContent>
				<SummaryTab />
				<NavLink to="/summary">
					<Button theme={{ bgColor: llmPrimary }}>
						{t('Summary.view_summary')}
					</Button>
				</NavLink>
			</IonCardContent>
		</StyledCard>
	)
}

export default React.memo(SummaryCard)
