import React from 'react'
import { IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react'
import styled from 'styled-components'

import { dark } from 'styles/colors'

// top header bar
const StyledToolbar = styled(IonToolbar)`
	--background: ${({ bgColor }) => bgColor || dark};

	img {
		margin-right: 10px;
		vertical-align: middle;
	}
`

const StyledTitle = styled(IonTitle)`
	padding: ${({ addPadding }) => (addPadding ? '0.5em' : '0')};

	img,
	ion-icon,
	span {
		vertical-align: middle;
	}
	img {
		filter: brightness(0) invert(1);
	}
	ion-icon {
		margin-right: 0.5em;
	}
`

const Header = ({ title, icon, onClickTitle, hideMenu, bgColor, children }) => {
	return (
		<IonHeader>
			<StyledToolbar bgColor={bgColor}>
				{!hideMenu && (
					<IonMenuButton
						slot="start"
						color="light"
						className="gtm-btn-menu"
					/>
				)}
				<StyledTitle
					color="light"
					onClick={onClickTitle}
					addPadding={hideMenu}
				>
					<span>{title}</span>
				</StyledTitle>
				{children}
			</StyledToolbar>
		</IonHeader>
	)
}

export default Header
