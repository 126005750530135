import Logger from '@lalamove/web-logger'
import { getUserId, noop } from 'utils/helpers'
import storage, { LOCALE, LOCATION } from 'utils/storage'

const {
	REACT_APP_LOG_URL,
	REACT_APP_LOG_KEY,
	REACT_APP_ENV,
	REACT_APP_VERSION,
} = process.env

const PLUS_LLM_ENV_MAP = {
	stage: 'test',
	beta: 'sandbox',
	prod: 'production',
}

let log = {
	// fallback for ldev:
	changeLogLevel: noop,
	changeUrl: noop,
	changeLocation: noop,
	changeLocale: noop,
	changeClientId: noop,
	debug: (...args) => { console.debug(...args) },
	info: (...args) => { console.info(...args) },
	warning: (...args) => { console.warn(...args) },
	error: (...args) => { console.error(...args) },
	fatal: (...args) => { console.error(...args) },
}

if (REACT_APP_ENV !== 'ldev') {
	const userId = getUserId()
	log = new Logger({
		url: REACT_APP_LOG_URL,
		key: REACT_APP_LOG_KEY,
		release: REACT_APP_VERSION,
		locale: storage.getItem(LOCALE) || 'en-HK',
		location: storage.getItem(LOCATION) || 'HK_HKG',
		environment: PLUS_LLM_ENV_MAP[REACT_APP_ENV] || 'dev',
		platform: 'webapp',
		appType: 'courier',
		...(userId && { clientId: userId.toString() }),
	})
}

export default log
