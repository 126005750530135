import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import ErrorBoundary from 'components/ErrorBoundary'
import store from 'redux/store'
import i18n from 'utils/i18n'
import log from 'utils/log'
import App from './App'
import * as serviceWorker from './serviceWorker'

const UPDATE_INTERVAL = 10 // minutes

ReactDOM.render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</I18nextProvider>
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// manually instruct the SW to fetch update
const fetchUpdate = async () => {
	console.log('Try to fetch update.')
	if ('serviceWorker' in navigator) {
		try {
			const registration = await navigator.serviceWorker.ready
			console.log('Fetching update...')
			const swRegObj = await registration.update()
			console.log('fetchUpdate-reg', swRegObj)
		} catch (e) {
			log.error('Service worker update failed', null, e.stack)
		}
	}
}

let updateTimer
if (!updateTimer) {
	updateTimer = setInterval(() => {
		fetchUpdate()
	}, UPDATE_INTERVAL * 60000)
}

// upon successfull SW update, tell the SW to skip waiting
// and immediately apply the updates, then reload the page
const updateNow = registration => {
	console.log('updateNow-reg', registration)
	const waitingServiceWorker = registration.waiting
	if (waitingServiceWorker) {
		console.log('Waiting...')
		waitingServiceWorker.addEventListener('statechange', event => {
			console.log('swstate', event.target.state)
			if (event.target.state === 'activated') {
				alert(`New version available. App will be updated.`)
				window.location.reload(true)
			}
		})
		waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
	}
}

const config = {
	onUpdate: updateNow,
	onSuccess: () => console.log('SW installed successfully'),
}
serviceWorker.register(config)
