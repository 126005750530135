import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IonSegment, IonSegmentButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { PARCEL_STATUS } from 'utils/constants'
import { readSummaryData, readSummarySortOrder } from 'utils/helpers'
import { getSummaryList, updateSummaryItem } from 'redux/summary'
import { getParcelsInfo, CROSSDOCK_STATUS_GROUP } from 'utils/helpers'
import storage, { CROSSDOCKS } from 'utils/storage'
const SummaryTab = ({ summaryList, tabKey, handleTabChange }) => {
	const { t } = useTranslation()
	const [deliveryList, setDeliveryList] = useState(summaryList)
	// populate the list from local storage
	const populateSummary = () => {
		const summary = readSummaryData()
		const parcels = summary.parcels || {}
		const orders = summary.orders || {}
		const addId = ([id, obj]) => ({ id, ...obj })
		const isNotDeleted = obj => !obj.deletedAt // filter out items that are marked as deleted..
		const list = Object.entries(parcels).map(addId).filter(isNotDeleted)
		list.push(...Object.entries(orders).map(addId).filter(isNotDeleted))
		setDeliveryList(list)
	}

	useEffect(() => {
		getParcelsInfo(updateSummaryItem)
	}, [])
	useEffect(() => {
		populateSummary()
	}, [summaryList])

	const {
		AWAITS_DRIVER,
		AWAITS_PICKUP,
		CANCELED,
		IN_PROGRESS,
		RESCHEDULED,
		DELIVERED,
		FAILED,
		PENDING,
		REJECTED,
		APPROVED,
		IN_THE_VEHICLE_CANCELED
	} = PARCEL_STATUS
	// count of each status type:
	const count = {
		[AWAITS_DRIVER]: 0,
		[AWAITS_PICKUP]: 0,
		[CANCELED]: 0,
		[IN_PROGRESS]: 0,
		[IN_THE_VEHICLE_CANCELED]: 0,
		[RESCHEDULED]: 0,
		[FAILED]: 0,
		[DELIVERED]: 0,
		[PENDING]: 0,
		[REJECTED]: 0,
		[APPROVED]: 0
	}
	deliveryList.forEach(item => {
		if (item.status === CANCELED) {
			const lastLog = (item.logs[item.logs.length - 1] || {}).status
			if (lastLog === IN_PROGRESS) {
				count[IN_THE_VEHICLE_CANCELED] += 1
				return
			}
		}
		count[item.status] += 1
	})

	const crossDocks = JSON.parse(storage.getItem(CROSSDOCKS)) || {}
	const crossDockList = crossDocks.list || []
	const filterCrossDock = (cd, tab) => {
		return (
			CROSSDOCK_STATUS_GROUP[tab].includes(cd.data.DOCKSTATUS) ||
			(tab === 'IN_THE_VEHICLE' && !cd.data.DOCKSTATUS)
		)
	}
	const getCrossDockTabList = tab =>
		crossDockList.slice().filter(item => filterCrossDock(item, tab))
	const summarySortOrder = readSummarySortOrder()
	let groupedCount
	if (summarySortOrder) {
		groupedCount = {
			TO_PICK_UP: summarySortOrder['TO_PICK_UP'].length,
			IN_THE_VEHICLE:
				summarySortOrder['IN_THE_VEHICLE'].length +
				getCrossDockTabList('IN_THE_VEHICLE').length,
			OFFLOADED:
				summarySortOrder['OFFLOADED'].length +
				getCrossDockTabList('OFFLOADED').length
		}
	} else {
		groupedCount = {
			TO_PICK_UP:
				count[AWAITS_DRIVER] + count[AWAITS_PICKUP] + count[CANCELED],
			IN_THE_VEHICLE:
				count[IN_PROGRESS] +
				count[RESCHEDULED] +
				count[FAILED] +
				count[PENDING] +
				count[REJECTED] +
				count[IN_THE_VEHICLE_CANCELED],
			OFFLOADED: count[DELIVERED] + count[APPROVED]
		}
	}

	return (
		<IonSegment color="dark" value={tabKey} onIonChange={handleTabChange}>
			{Object.entries(groupedCount).map(([key, count]) => (
				<IonSegmentButton
					key={key}
					value={key}
					id={`${key}-button`}
					className={`gtm-btn-summary-tab-${key}`}
					disabled={handleTabChange ? false : 'disabled'}
					style={handleTabChange ? {} : { opacity: 1 }}
				>
					<div>{t(`Summary.label_${key.toLowerCase()}`)}</div>
					<div>×{count}</div>
				</IonSegmentButton>
			))}
		</IonSegment>
	)
}
const mapStateToProps = state => ({
	summaryList: getSummaryList(state)
})

export default connect(mapStateToProps, {
	updateSummaryItem
})(SummaryTab)
