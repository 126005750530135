import React from 'react'
import { noop } from 'utils/helpers'
import Modal from './Modal'
import Toast from './Toast'

const ErrorDisplay = ({ isOpen, message, requestId, blocking, duration, onDismiss }) => {
	const ErrorComp = blocking ? Modal : Toast
	return (
		<ErrorComp
			isOpen={isOpen}
			message={message}
			requestId={requestId}
			duration={duration * 1000}
			onDismiss={onDismiss}
		/>
	)
}

ErrorDisplay.defaultProps = {
	isOpen: false,
	message: '',
	requestId: '',
	blocking: false,
	duration: 0,
	onDismiss: noop,
}

export default ErrorDisplay
