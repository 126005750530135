// TODO:
// Consider to move API requests helper functions to /courier/src/api/..
// This file will contain only generic utility helpers.
import moment from 'moment'
import axiosApiClient from 'api/axiosApiClient'
import i18n from 'utils/i18n'
import { PARCEL_STATUS, FORM_TAG_STATUS_MAP } from 'utils/constants'
import log from 'utils/log'
import { updateSummaryItem } from 'redux/summary'
import storage, {
	LAST_PAGE,
	CLIENTS,
	LOCATION,
	LOCATIONS,
	LOCALE,
	TOKEN,
	SHIFT,
	SHIFT_HISTORY,
	FORM_MODELS,
	SUBMIT_ATTEMPTS,
	SUMMARY,
	CROSSDOCKS,
	SUMMARY_QUICK_ACTIONS,
	MENU_CONFIG_CACHE,
	SUMMARY_SORT_ORDER
} from 'utils/storage'

const {
	AWAITS_DRIVER,
	AWAITS_PICKUP,
	CANCELED,
	IN_PROGRESS,
	RESCHEDULED,
	DELIVERED,
	FAILED,
	CROSSDOCKED,
	PENDING,
	REJECTED,
	RETURNED,
	APPROVED
} = PARCEL_STATUS

const { REACT_APP_ENABLE_LLMP_289_CROSSDOCK } = process.env
const ENABLE_CROSSDOCK = REACT_APP_ENABLE_LLMP_289_CROSSDOCK === 'true'

export const FILTERS = {
	TO_PICK_UP: [AWAITS_DRIVER, AWAITS_PICKUP, CANCELED],
	IN_THE_VEHICLE: [
		IN_PROGRESS,
		RESCHEDULED,
		FAILED,
		...(ENABLE_CROSSDOCK ? [CROSSDOCKED] : []),
		CANCELED
	],
	OFFLOADED: [DELIVERED, ...(ENABLE_CROSSDOCK ? [CROSSDOCKED] : []), RETURNED]
}

export const CROSSDOCK_STATUS_GROUP = {
	TO_PICK_UP: [],
	IN_THE_VEHICLE: [PENDING, REJECTED],
	OFFLOADED: [APPROVED]
}

export const DEFAULT_LOCALE = 'en'
const LOCATION_TIMEOUT = 2 // seconds

export const initLocale = locale => {
	i18n.changeLanguage(locale || storage.getItem(LOCALE) || DEFAULT_LOCALE)
}

export const changeLocale = async locale => {
	storage.setItem(LOCALE, locale)
	await i18n.changeLanguage(locale)
	log.changeLocale(locale)
	try {
		if (storage.getItem(TOKEN) !== null) {
			const user = await axiosApiClient.get('users/self')
			user.data.info.locale = locale
			await axiosApiClient.patch('/users/self', { info: user.data.info })
		}
	} catch (e) {
		log.error('Failed to update user info', { category: 'API' }, e.stack)
	}
}

// gets translation value from api payload
export const getTranslationValue = (translations, fallback, key = 'value') => {
	const locale = i18n.language || DEFAULT_LOCALE
	const found = translations.find(
		translation => translation.locale === locale
	)
	// use fallback if translation not found
	return found && found[key] ? found[key] : fallback
}

export const readSummaryData = () => JSON.parse(storage.getItem(SUMMARY)) || {}
export const saveSummaryData = summary =>
	storage.setItem(SUMMARY, JSON.stringify(summary))

export const readSummarySortOrder = () =>
	JSON.parse(storage.getItem(SUMMARY_SORT_ORDER)) || null
export const saveSummarySortOrder = summarySortOrder =>
	storage.setItem(SUMMARY_SORT_ORDER, JSON.stringify(summarySortOrder))

export const selectiveDeleteSummaryData = (summary, date) => {
	if (!summary.updatedAt) return
	const updatedAt = new Date(summary.updatedAt)
	// skip if it was updated on the same day as the given date (i.e. today):
	if (!updatedAt || updatedAt.toDateString() === date.toDateString()) return
	// deletion logic:
	const deleteData = data => {
		if (!data) return
		for (const key of Object.keys(data)) {
			let isDeletedOnSameDate = false
			let isDeletedOnOtherDate = false
			let isFinalStatus = false
			// check if item is deleted, on the same date or not:
			if (data[key].deletedAt) {
				const deletedAt = new Date(data[key].deletedAt)
				if (deletedAt) {
					isDeletedOnSameDate =
						deletedAt.toDateString() === date.toDateString()
					isDeletedOnOtherDate = !isDeletedOnSameDate
				}
			}
			// check if status is final:
			const removableStatus = [
				PARCEL_STATUS.DELIVERED,
				PARCEL_STATUS.CROSSDOCKED,
				PARCEL_STATUS.APPROVED
			]
			if (removableStatus.includes(data[key].status)) {
				isFinalStatus = true
			}
			// delete..
			if (isDeletedOnOtherDate || (isFinalStatus && !isDeletedOnSameDate))
				delete data[key]
		}
	}
	deleteData(summary.parcels)
	deleteData(summary.orders)
	return summary
}

// clear summary data in local storage
export const clearSummaryData = () => {
	const today = new Date()
	const summary = selectiveDeleteSummaryData(readSummaryData(), today)
	if (summary) saveSummaryData(summary)

	// crossdocks:
	const crossDocks = JSON.parse(storage.getItem(CROSSDOCKS))
	if (crossDocks && crossDocks.updatedAt) {
		const updatedAt = new Date(crossDocks.updatedAt)
		// remove APPROVED CROSSDOCKS if it was updated before today
		if (
			updatedAt &&
			updatedAt.toDateString() !== new Date().toDateString()
		) {
			crossDocks.list = crossDocks.list.filter(
				i => i.data.DOCKSTATUS !== PARCEL_STATUS.APPROVED
			)
			crossDocks.updatedAt = new Date().toISOString()
			storage.setItem(CROSSDOCKS, JSON.stringify(crossDocks))
		}
	}
}

export const readSubmitAttempts = () =>
	JSON.parse(storage.getItem(SUBMIT_ATTEMPTS)) || {}
export const saveSubmitAttempts = (queue, updatedAt) => {
	if (queue && queue.length) {
		if (!updatedAt) {
			updatedAt = new Date().toISOString()
		}
		storage.setItem(SUBMIT_ATTEMPTS, JSON.stringify({ queue, updatedAt }))
	} else {
		storage.removeItem(SUBMIT_ATTEMPTS)
	}
}

// clear pending submit attempts from the previous day
export const clearSubmitAttempts = () => {
	const attempts = JSON.parse(storage.getItem(SUBMIT_ATTEMPTS))
	if (attempts && attempts.updatedAt) {
		const updatedAt = new Date(attempts.updatedAt)
		if (
			updatedAt &&
			updatedAt.toDateString() !== new Date().toDateString()
		) {
			storage.removeItem(SUBMIT_ATTEMPTS)
		}
	}
}

// gets clients with fallback: fetch -> local storage
export const getClients = async () => {
	const json = JSON.parse(storage.getItem(CLIENTS)) || []
	let clients = []
	try {
		const response = await axiosApiClient.get('clients?limit=25')
		clients = response.data // use fetched clients
		storage.setItem(CLIENTS, JSON.stringify(clients))
	} catch (error) {
		// fallback to cached clients
		clients = json
	}
	return clients
}

export const fetchClientsOfCurrentLocation = async () => {
	const locationId = storage.getItem(LOCATION)
	const clients = await getClients()
	return clients.filter(
		client =>
			!client.locations.length || client.locations.includes(locationId)
	)
}

// gets form models by client from storage
export const getFormModelsByClientId = async clientId => {
	const json = JSON.parse(storage.getItem(FORM_MODELS)) || {}
	return json[clientId] || []
}

// fetches all forms of the current location and cache them
// fallbacks to cache if request fails
export const fetchFormsOfCurrentLocation = async () => {
	const locationId = storage.getItem(LOCATION)
	const json = JSON.parse(storage.getItem(FORM_MODELS)) || {}
	let data = {}
	try {
		const response = await axiosApiClient.get(
			`forms?limit=100&locationId=${locationId}`
		)
		// use fetched forms:
		response.data.forEach(form => {
			const clientId = form.clientId || 'COMMON'
			if (data[clientId]) {
				data[clientId].push(form)
			} else {
				data[clientId] = [form]
			}
		})
		storage.setItem(FORM_MODELS, JSON.stringify(data))
	} catch (error) {
		// fallback to cached forms
		data = json
	}
	return data
}

export const getCrossdock = async (dockId, eTag) => {
	try {
		const response = await axiosApiClient.get(`crossdocks/${dockId}`, {
			headers: { 'If-None-Match': eTag }
		})
		return response
	} catch (error) {
		log.error('getCrossdock failed', { category: 'API' }, error.stack)
		return null
	}
}

// POST request to create shift
export const createShift = async () => {
	const data = {
		userId: getUserId(),
		location: getLocationId(),
		date: moment().add(1, 'd').format('YYYY-MM-DD') // shift is always created for the next day
	}
	const response = await axiosApiClient.post('shifts', data)
	return response
}

// PATCH request to update shift's status
export const updateShift = async (id, status) => {
	const response = await axiosApiClient.patch(`shifts/${id}`, { status })
	return response
}

// GET request to fetch shift by ID
export const fetchShift = async id => {
	const response = await axiosApiClient.get(`shifts/${id}`)
	return response
}

// GET request to fetch shift by current location and date
export const fetchShiftByLocationAndDate = async date => {
	const locationId = storage.getItem(LOCATION)
	const response = await axiosApiClient.get(
		`shifts?location=${locationId}&date=${date}`
	)
	return response
}

// GET request to fetch route by ID
export const fetchRoute = async id => {
	const response = await axiosApiClient.get(`routes/${id}`)
	return response
}

// read all shifts data from storage
export const readAllShifts = () => JSON.parse(storage.getItem(SHIFT))

// save all shifts data to storage
export const saveAllShifts = values => {
	storage.setItem(SHIFT, JSON.stringify(values))
}

// save shift data for specific day (today/tomorrow) to storage
export const saveShift = (day, value) => {
	const s = JSON.parse(storage.getItem(SHIFT)) || {}
	s[day] = value
	storage.setItem(SHIFT, JSON.stringify(s))
}

// fetches shifts of the current location and cache them
// fallbacks to cache if request fails
export const fetchShiftsOfCurrentLocation = async () => {
	const location = storage.getItem(LOCATION)
	const json = JSON.parse(storage.getItem(SHIFT_HISTORY)) || {}
	let shifts = []
	try {
		const params = {
			min_date: moment().subtract(15, 'd').format('YYYY-MM-DD'),
			max_date: moment().subtract(1, 'd').format('YYYY-MM-DD'),
			location
		}
		const { data } = await axiosApiClient.get('shifts', { params })
		// use fetched shifts:
		shifts = data
		storage.setItem(SHIFT_HISTORY, JSON.stringify(data))
	} catch (error) {
		// fallback to cached shifts:
		shifts = json
	}
	return shifts
}

export const fetchDelivery = async (clientId, clientRef) => {
	const params = {
		courierId: getUserId(),
		clientId,
		clientRef,
		clientRefType: 'PARCEL',
		limit: 1
	}
	try {
		const { data } = await axiosApiClient.get('deliveries', { params })
		return data[0]
	} catch (err) {
		log.error('fetchDelivery failed', { category: 'API' }, err.stack)
	}
}

export const fetchDeliveries = async () => {
	const params = {
		courierId: getUserId(),
		min_deliveryBy: moment().startOf('day').toISOString(),
		limit: 200
	}
	try {
		const { data } = await axiosApiClient.get('deliveries', { params })
		return data
	} catch (err) {
		log.error('fetchDeliveries failed', { category: 'API' }, err.stack)
	}
}

// determine OS from userAgent string
export const getOs = () => {
	const { userAgent } = navigator
	const matches = userAgent.match(/.*?\((.+?)\).*/)
	return matches && matches.length > 1 ? matches[1] : userAgent
}

// determine browser from userAgent string
export const getBrowser = () => {
	const { userAgent: ua } = navigator
	const browsers = [
		'Puffin',
		'YaBrowser',
		'MiuiBrowser',
		'UCBrowser',
		'IEMobile',
		'MSIE',
		'Trident',
		'OPR',
		'OPiOS',
		'Opera',
		'SamsungBrowser',
		'Edg',
		'Edge',
		'FxiOS',
		'Firefox',
		'CriOS',
		'Chrome',
		'Safari'
	]
	let retval = ua
	for (let browser of browsers) {
		const matches = ua.match(new RegExp(`^.*(${browser}/[\\.\\d]+).*$`))
		const result = matches && matches.length > 1 ? matches[1] : ''
		if (result) {
			retval = result
			break
		}
	}
	return retval
}

export const isIphone = () => !!navigator.userAgent.match(/iPhone/i)

export const initDeviceMetaData = () => {
	// check if Standalone (i.e. added to home screen):
	const standalone =
		(window.matchMedia &&
			window.matchMedia('(display-mode: standalone)').matches) || // Android
		navigator.standalone === true // iOS

	const { REACT_APP_NAME, REACT_APP_VERSION } = process.env
	return {
		app: REACT_APP_NAME,
		ver: REACT_APP_VERSION,
		browser: getBrowser(), // get Browser
		standalone,
		os: getOs(), // get OS
		lat: 0,
		lng: 0
	}
}

// calls Geolocaton API
export const getCurrentPosition = timeout =>
	new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(resolve, reject, {
			timeout: timeout * 1000
		})
	})

// calculate distance of 2 coordinate points with Haversine formula
export const coordDistance = (p1, p2) => {
	const R = 6371e3 // earth radius in metres
	const toRad = Math.PI / 180
	const lat1 = p1.lat * toRad
	const lat2 = p2.lat * toRad
	const latDiff = (p2.lat - p1.lat) * toRad
	const lngDiff = (p2.lng - p1.lng) * toRad

	const a =
		Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
		Math.cos(lat1) *
			Math.cos(lat2) *
			Math.sin(lngDiff / 2) *
			Math.sin(lngDiff / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	const d = R * c
	return d
}

export const setLocation = async (meta = {}) => {
	meta.lat = 0
	meta.lng = 0
	meta.permissions = { location: 'granted' }
	try {
		const { coords } = await getCurrentPosition(LOCATION_TIMEOUT)
		if (coords) {
			meta.lat = coords.latitude
			meta.lng = coords.longitude
		}
	} catch (e) {
		if (e.code === 1) {
			meta.permissions = { location: 'denied' }
		}
		log.error('Failed to get current position', null, e.stack)
	}
}

export const mapIcon = key => {
	const map = {
		DROP_OFF: 'dropoff.png',
		PICKUP: 'pickup.png',
		CROSSDOCK: 'docking.png',
		SHIFT: 'proof.png',
		EXPENSE: 'expense.svg',
		AVAILABILITY: 'availability.png',
		SUMMARY: 'summary.svg',
		DROP_OFF_SUCCESS: 'success.svg',
		DROP_OFF_FAILURE: 'failure.svg',
		DROP_OFF_WAREHOUSE: 'warehouse.png',
		PICKUP_LAST_MILE: 'warehouse.svg',
		PICKUP_FIRST_MILE: 'customer.svg',
		CROSSDOCK_PICKUP: 'crossdockoutbound.png',
		CROSSDOCK_DROP_OFF: 'crossdockinbound.png',
		TELEGRAM: 'telegram.svg'
	}
	return map[key] || 'empty.png'
}

export const noop = () => {}

export const findParcelFromSummary = values => {
	const parcels = readSummaryData().parcels || {}
	const value = values.find(v => parcels[v])
	return value ? parcels[value] : null
}

export const updateCrossDockItemsInSummary = (list, status) => {
	const summary = readSummaryData()
	const parcels = summary.parcels || {}
	list.forEach(item => {
		if (parcels[item] && parcels[item].status === PARCEL_STATUS.PENDING) {
			parcels[item].status = status
			// update pending status in logs:
			const i = parcels[item].logs.findIndex(
				l => l.status === PARCEL_STATUS.PENDING
			)
			if (i >= 0)
				parcels[item].logs[i] = {
					status,
					time: new Date().toISOString()
				}
		}
	})
	saveSummaryData({
		...summary,
		parcels,
		updatedAt: new Date().toISOString()
	})
}

// gets locations
export const getLocations = async () => {
	const json = JSON.parse(storage.getItem(LOCATIONS)) || []
	let locations = []
	try {
		const response = await axiosApiClient.get('locations')
		locations = response.data // use feched locations
		storage.setItem(LOCATIONS, JSON.stringify(locations))
	} catch (error) {
		// fallback to cached locations
		locations = json
	}
	return locations
}

// DOM helpers for IonInput with React Ref:
export const isTextInput = ref => {
	const { children } = ref.current
	return (
		children.length > 0 &&
		children[0] instanceof HTMLInputElement &&
		children[0].type === 'text'
	)
}

export const setFocus = ref => {
	if (isTextInput(ref)) {
		ref.current.children[0].focus()
	}
}

export const getSelectionStart = ref => {
	if (isTextInput(ref)) {
		return ref.current.children[0].selectionStart
	}
	return 0
}

export const getSelectionEnd = ref => {
	if (isTextInput(ref)) {
		return ref.current.children[0].selectionEnd
	}
	return 0
}

// gets summary quick actions
export const getSummaryQuickActions = async () => {
	const json = JSON.parse(storage.getItem(SUMMARY_QUICK_ACTIONS)) || []
	let summaryQuickActions = {}
	try {
		const response = await axiosApiClient.get('summary-status-actions')
		summaryQuickActions = response.data.reduce((accumulator, value) => {
			const { clientId } = value
			if (accumulator[clientId] === undefined) {
				accumulator[clientId] = [value]
			} else {
				accumulator[clientId].push(value)
			}
			return accumulator
		}, {})
		storage.setItem(
			SUMMARY_QUICK_ACTIONS,
			JSON.stringify(summaryQuickActions)
		)
	} catch (error) {
		// fallback to cached summaryQuickActions
		summaryQuickActions = json
	}
	return summaryQuickActions
}

export const parseUrlParams = string =>
	string.trim() === ''
		? {}
		: string
				.slice(1)
				.split('&')
				.map(p => p.split('='))
				.reduce(
					(obj, [key, value]) => ({
						...obj,
						[key]: decodeURIComponent(value)
					}),
					{}
				)

// parses JWT token
export const parseToken = token => {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	)
	return JSON.parse(jsonPayload)
}

// check if JWT token is still valid
export const checkTokenValidity = () => {
	const token = storage.getItem(TOKEN)
	if (!token) return false
	let valid = false
	try {
		const { exp } = parseToken(token)
		valid = exp * 1000 > Date.now()
	} catch (e) {
		log.error('Failed to parse token', null, e.stack)
	}
	return valid
}

// gets user ID from JWT token
export const getUserId = () => {
	const token = storage.getItem(TOKEN)
	if (!token) return null
	try {
		const { sub } = parseToken(token)
		return parseInt(sub)
	} catch (e) {
		log.error('Failed to parse token', null, e.stack)
	}
	return null
}

// gets roles from JWT token
export const getRoles = () => {
	const token = storage.getItem(TOKEN)
	if (!token) return []
	try {
		const { aud } = parseToken(token)
		return aud
	} catch (e) {
		log.error('Failed to parse token', null, e.stack)
	}
	return []
}

// gets current location ID from storage
export const getLocationId = () => storage.getItem(LOCATION)

export const logout = () => {
	storage.removeItem(TOKEN)
	if (!window.location.pathname.startsWith('/login')) {
		storage.setItem(LAST_PAGE, window.location.pathname)
		window.location.href = '/login'
	}
}

export const validateUser = () => {
	if (!checkTokenValidity()) {
		alert('Please re-login.')
		logout()
	}
}

// find proper format (moved from Barcode.js):
export const findFormat = (formats, value) => {
	if (!value) return ''
	if (!formats.length) return ''
	// check if there is at least 1 format:
	if (!formats.some(format => !!format)) return ''
	let maxLength = 0 // length of longest format
	let maxIndex = 0 // index of longest format
	let equalIndex = -1 // index of format with length equal to value
	formats.forEach((format, index) => {
		// format length is defined as the number of `?`:
		const formatLength = format.split('?').length - 1
		if (formatLength > maxLength) {
			maxLength = formatLength
			maxIndex = index
		}
		if (formatLength === value.length && equalIndex < 0) {
			equalIndex = index
		}
	})

	if (equalIndex >= 0) {
		// if value length equals to one of the formats,
		// return the 1st format with same length as value:
		return formats[equalIndex]
	}
	if (value.length > maxLength) {
		// if value length is longer than any format,
		// return longest format:
		return formats[maxIndex]
	}
	// otherwise return first format:
	return formats[0]
}

// formats the value with a given format (moved from Barcode.js):
export const formatDisplay = (value, format) => {
	if (!format) return value
	let formatted = ''
	let f = 0 // format index
	let v = 0 // value index
	// iterate through format:
	while (f < format.length) {
		// do formatting if there are still remaining value symbols:
		if (v < value.length) {
			if (format[f] === '?') {
				// concat with actual symbol from value if found `?`
				formatted += value[v]
				v += 1
			} else {
				// otherwise, concat with symbol from format, e.g. ` `
				formatted += format[f]
			}
			// handle intermediates and suffix:
		} else if (format[f] !== '?') {
			formatted += format[f]
		} else {
			break
		}
		f += 1
	}
	// if there are still remaining value symbols at the end of format,
	// just concat them at the end:
	if (v < value.length) formatted += ` ${value.slice(v)}`
	return formatted
}

export const findFormatAndDisplay = (formats, value) =>
	formatDisplay(value, findFormat(formats, value))

// cleans clienRefs values (used for both scanned and manually inputted items)
// - removes whitespaces
// - convert to upper case
// - others in the future..
export const cleanClientRef = value =>
	process.env.REACT_APP_ENABLE_LLMP_594_REMOVE_CLIENTREF_WHITESPACE === 'true'
		? value.replace(/\s/g, '').toUpperCase()
		: value.toUpperCase()

export const getMenuConfig = async () => {
	let data

	try {
		const response = await axiosApiClient.get('/courier-menu')
		data = response.data.config
		storage.setItem(MENU_CONFIG_CACHE, JSON.stringify(response.data.config))
	} catch (error) {
		data = JSON.parse(storage.getItem(MENU_CONFIG_CACHE))

		if (!data) {
			log.error('getMenuConfig failed', { category: 'API' }, error.stack)
			data = {
				items: [],
				defaultItemId: null
			}
		}
	}
	return data
}

// only supports '.' paths
export const getValueByPath = (obj, path, defaultVal) => {
	return (
		path.split('.').reduce((acc, part) => acc && acc[part], obj) ||
		defaultVal
	)
}

export const getShortenDeliveryStageType = stageType => {
	const deliveryStageTypeMapping = {
		FIRST_MILE: 'F',
		LAST_MILE: 'L',
		FIRST_MILE_PICKUP: 'PU',
		LAST_MILE_DISTRIBUTION: 'DO',
		INSTANT_DELIVERY: 'NOW',
		DIRECT_TO_CUSTOMER: 'ToC'
	}

	return deliveryStageTypeMapping[stageType] || ''
}

export const getParcelsInfo = async () => {
	const deliveries = await fetchDeliveries()
	if (!deliveries) return
	const summary = readSummaryData()
	const parcels = summary.parcels || {}
	const orders = summary.orders || {}

	const clientRefsValues = [] // all available client refs from deliveries

	for (const delivery of deliveries) {
		const orderId = (
			delivery.clientRefs.find(c => c.type === 'ORDER') || {}
		).value
		const parcelId = (
			delivery.clientRefs.find(c => c.type === 'PARCEL') || {}
		).value
		const updatedAt = new Date(delivery.updatedAt)
		// skip if status is delivered and updatedAt !== today
		if (
			updatedAt.toDateString() !== new Date().toDateString() &&
			delivery.status === DELIVERED
		)
			continue
		if (orderId) clientRefsValues.push(orderId)
		if (parcelId) clientRefsValues.push(parcelId)
		let orderLogs = []
		let formsLogs = []
		let newData
		// get order info:
		if (delivery.order) {
			const {
				address,
				contacts,
				pickupAt,
				deliveryBy,
				createdAt,
				changeLogs,
				status,
				notes,
				extras,
				clientId,
				deliveryStage
			} = delivery.order
			// for backward compatibility with old contacts:
			address.forEach(addr => {
				if (!addr.contacts && contacts && contacts.length) {
					addr.contacts = contacts
				}
			})
			orderLogs.push({ status: AWAITS_PICKUP, time: createdAt })

			const clientList = JSON.parse(storage.getItem('clients'))
			const client = clientList.find(i => i.id === clientId)
			const remarksTpl = getValueByPath(
				client,
				'attributes.orderRemarksTemplate',
				'{ notes }'
			)
			const remarks = i18n.t(remarksTpl, {
				notes,
				extras: extras && extras.length ? extras[0] : undefined
			})

			// to mark changes:
			const changed = []
			if (changeLogs && changeLogs.length) {
				const oriOrder = changeLogs[changeLogs.length - 1]

				if (pickupAt !== oriOrder.pickupAt) {
					changed.push('pickupAt')
				}

				if (deliveryBy !== oriOrder.deliveryBy) {
					changed.push('deliveryBy')
				}

				if (
					remarks !==
					i18n.t(remarksTpl, {
						notes: oriOrder.notes,
						extras: oriOrder.extras
					})
				) {
					changed.push('remarks')
				}

				// for backward compatibility with old contacts:
				oriOrder.address.forEach(addr => {
					if (
						!addr.contacts &&
						oriOrder.contacts &&
						oriOrder.contacts.length
					) {
						addr.contacts = oriOrder.contacts
					}
				})
				// 0: pickup; 1: dropoff
				for (let i = 0; i < 2; i += 1) {
					const addr = address[i] || {}
					const oriAddr = oriOrder.address[i] || {}
					const contact = (addr.contacts || [])[0] || {}
					const oriContact = (oriAddr.contacts || [])[0] || {}
					if (addr.text !== oriAddr.text) {
						changed.push(`address${i}`)
					}
					if (contact.name !== oriContact.name) {
						changed.push(`name${i}`)
					}
					if (contact.phone !== oriContact.phone) {
						changed.push(`phone${i}`)
					}
				}
			}
			const deliveryType =
				extras && extras[0] ? extras[0].deliveryType : null
			const _extras = extras && extras[0]
			if (_extras && _extras.deliveryType) {
				delete _extras.deliveryType
			}
			// override display status if CANCELED (only if there is no form-submission!):
			newData = {
				address,
				pickupAt,
				deliveryBy,
				changed,
				remarks,
				extras: _extras,
				deliveryStage,
				deliveryType,
				...(status === 'CANCELED' || status === 'CLOSED'
					? { status: 'CANCELED' }
					: {})
			}
		}
		// populate from-submissions logs:
		for (const fs of delivery.formSubmissions) {
			let status = FORM_TAG_STATUS_MAP[fs.tag]
			if (!status) continue
			const isRejected = fs.fields.some(
				f => f.tag === 'DOCKSTATUS' && f.values[0] === REJECTED
			)
			if (isRejected) continue

			// special handling for crossdock status:
			if (status === PENDING) {
				// if CROSSDOCK_DROP_OFF, find matching CROSSDOCK_PICKUP:
				const outboundCdFs = delivery.formSubmissions.find(
					cdFs => cdFs.tag === 'CROSSDOCK_PICKUP'
				)
				if (outboundCdFs) {
					const dockId = fs.fields.find(f => f.tag === 'DOCKID')
						.values[0]
					const matchingCd = outboundCdFs.fields.some(
						f => f.tag === 'DOCKID' && f.values[0] === dockId
					)
					if (matchingCd) {
						status = outboundCdFs.fields.find(
							f => f.tag === 'DOCKSTATUS'
						).values[0]
					}
				}
			}
			const isReschduled = fs.fields.some(
				f => f.tag === 'REASONS_FAILURE' && f.values[0].startsWith('17')
			)
			formsLogs.push({
				status: isReschduled ? RESCHEDULED : status,
				time: fs.submittedAt
			})
		}
		// sort by time (formSubmission.submittedAt):
		formsLogs.sort((a, b) => new Date(a.time) - new Date(b.time))
		// sync logs:
		if (formsLogs.length) {
			if (!newData) newData = {}
			newData.logs = [...orderLogs, ...formsLogs]
		} else if (orderLogs.length) {
			const logs =
				parcelId && parcels[parcelId] ? parcels[parcelId].logs : []
			if (!logs.some(log => log.status === AWAITS_PICKUP)) {
				newData.logs = [...orderLogs, ...logs]
			}
		}

		// save the order ID into existing data:
		if (orderId) {
			if (!newData) newData = {}
			newData.orderId = orderId
		}
		// if there is any updated data, save them:
		if (newData) {
			newData.clientId = delivery.clientId
			updateSummaryItem(parcelId, newData)
			if (parcelId) {
				if (!parcels[parcelId]) {
					newData.status =
						newData.logs[newData.logs.length - 1].status
				}
				parcels[parcelId] = { ...parcels[parcelId], ...newData }
			} else if (orderId) {
				newData.status =
					newData.status ||
					newData.logs[newData.logs.length - 1].status
				orders[orderId] = { ...orders[orderId], ...newData }
			}
		}
	}

	// remove to-pick-up (AWAITS_PICKUP) items if they are no longer returned in deliveries
	// case: order originally assigned to me, but taken by other courier..
	const removeItems = data => {
		for (const key of Object.keys(data)) {
			if (
				data[key].status === AWAITS_PICKUP &&
				!clientRefsValues.includes(key)
			) {
				delete data[key]
			}
		}
	}

	removeItems(parcels)
	removeItems(orders)

	if (!summary.updatedAt) summary.updatedAt = new Date().toISOString()
	saveSummaryData({ ...summary, parcels, orders })
}

export const getSummaryParcelStatusTabKey = parcel => {
	const result = Object.keys(FILTERS).filter(key =>
		FILTERS[key].includes(parcel.status)
	)
	if (parcel.status === CANCELED) {
		const latestLog = parcel.logs[parcel.logs.length - 1]
		if (latestLog.status === IN_PROGRESS) {
			return result[1]
		}
	}
	return result[0]
}
