// 1. Sction types:
export const SUMMARY_INIT = 'SUMMARY_INIT'
export const SUMMARY_UPDATE = 'SUMMARY_UPDATE'
export const SUMMARY_ITEM_ADD = 'SUMMARY_ITEM_ADD'
export const SUMMARY_ITEM_REMOVE = 'SUMMARY_ITEM_REMOVE'
export const SUMMARY_ITEM_UPDATE_STATUS = 'SUMMARY_ITEM_UPDATE_STATUS'
export const SUMMARY_ITEM_UPDATE = 'SUMMARY_ITEM_UPDATE'
// etc.
// 2. Action creators:
export const initSummary = list => ({
	type: SUMMARY_INIT,
	list,
})
export const updateSummaryItemStatus = (itemId, newStatus, isUploaded) => ({
	type: SUMMARY_ITEM_UPDATE_STATUS,
	itemId,
	newStatus,
	isUploaded,
})
export const updateSummaryItem = (itemId, data) => ({
	type: SUMMARY_ITEM_UPDATE,
	itemId,
	data,
})
// 3. Initial states:
const initState = {
	list: [] // list item will be like: { id: '1111', status: 'DELIVERED', pickedUp: true, uploaded: false }
}
// 4. Selectors:
export const getSummaryList = state => state.summary.list
export const getSummaryItemById = (state, id) => state.summary.list.find(item => item.id === id)
// etc.
// 5. Reducers:
export default function reducer(state = initState, action) {
	const { type, list, itemId, newStatus, isUploaded, data } = action
	switch (type) {
		case SUMMARY_INIT: {
			const newState = Object.assign({}, state, { list })
			return newState
		}
		case SUMMARY_ITEM_UPDATE_STATUS: {
			const newList = state.list.map(item => { 
				if (item.id === itemId) {
					item.status = newStatus
					if (isUploaded) delete item.pending
					else item.pending = true
				}
				return item
			})
			const newState = Object.assign({}, state, { list: newList })
			return newState
		}
		case SUMMARY_ITEM_UPDATE: {
			return {
				...state,
				list: state.list.map(item => { 
					if (item.id === itemId) {
						item = { ...item, ...data }
					}
					return item
				})
			}
		}
		default:
			// return current state
			return state
	}
}