import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
	IonContent,
	IonModal,
	IonPage,
	IonSpinner,
	useIonViewWillEnter,
} from "@ionic/react"
import styled from "styled-components"
import Button from "components/Button"
import ButtonGrid from "components/ButtonGrid"
import ConnectedForm from "containers/ConnectedForm"
import {
	clearSummaryData,
	getFormModelsByClientId,
	validateUser,
} from "utils/helpers"
import storage, { LAST_PAGE } from "utils/storage"

const CustomIonModal = styled(IonModal)`
	.ion-page {
		padding-top: var(--ion-safe-area-top, 0);
	}
`

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	margin: 1em;
`

const Title = styled.h1`
	font-size: 18px;
`

const FormPage = ({
	clients,
	clientSelect,
	tags,
	formGroup,
	defaultForms,
	defaultIndex,
	defaultPath,
	translations,
	icon,
	clientCommonForms,
}) => {
	const defaultClientId = clients.length === 1 ? clients[0].id : null
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const [clientId, setClientId] = useState(defaultClientId)
	const [forms, setForms] = useState(defaultForms)
	const [displayIndex, setDisplayIndex] = useState(defaultIndex)

	useIonViewWillEnter(() => {
		clearSummaryData()
		storage.setItem(LAST_PAGE, window.location.pathname)
		validateUser()
		if (defaultClientId) selectClient(defaultClientId)
		else {
			setClientId(defaultClientId)
			setDisplayIndex(defaultIndex)
		}
	})

	const handleIndexChange = (index) => {
		setDisplayIndex(index)
	}

	const selectClient = async (id) => {
		setLoading(true)
		setClientId(id)
		// get forms by client ID from storage:
		const data = await getFormModelsByClientId(id)
		const formsInTheGroup = []
		tags.forEach((tag) => {
			const form = data.find((d) => d.tag === tag)
			const commonForm = clientCommonForms.find((d) => d.tag === tag)
			if (form) formsInTheGroup.push(form)
			else if (commonForm) formsInTheGroup.push(commonForm)
		})
		if (formsInTheGroup.length) setForms(formsInTheGroup)
		// if there are forms available for this clientId, use them
		else setForms(defaultForms) // otherwise, fallback to defaultForms (COMMON) if any
		if (formsInTheGroup.length === 1) setDisplayIndex(0)
		setLoading(false)
	}

	const renderContent = () => {
		if (loading) {
			return <IonSpinner style={{ margin: "auto" }} />
		} else if (forms.length) {
			return (
				<ConnectedForm
					translations={translations}
					icon={icon}
					clients={clients}
					clientId={clientId}
					formGroup={formGroup}
					models={forms}
					displayIndex={displayIndex}
					onIndexChange={handleIndexChange}
				/>
			)
		} else {
			return (
				<Container>
					<img src="assets/icon/noresult.svg" width="200" height="200" alt="" />
					{/* TODO: Confirm texts/translations */}
					<Title>{`No ${t(`${formGroup}.title`)} form for ${
						clientId || "COMMON"
					}`}</Title>
					<Button routerLink={defaultPath} className="gtm-btn-return-home">
						Return Home
					</Button>
				</Container>
			)
		}
	}

	return (
		<IonPage id="formpage">
			{/* client selection modal: */}
			<CustomIonModal
				isOpen={clientSelect && !clientId}
				backdropDismiss={false}
				animated={false}
			>
				<IonContent>
					<p style={{ textAlign: "center" }}>
						{t("Form.label_select_client")}:
					</p>
					<ButtonGrid list={clients} onClick={selectClient} />
				</IonContent>
			</CustomIonModal>
			{renderContent()}
		</IonPage>
	)
}

FormPage.defaultProps = {
	defaultForms: [],
	defaultIndex: 0,
	defaultPath: "/",
	clientSelect: true,
}

export default withRouter(FormPage)
