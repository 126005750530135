// dropoff form field types
export const FIELD_TYPE = {
	BARCODE: 'BARCODE',
	TEXT: 'TEXT',
	RADIO: 'RADIO',
	SIGNATURE: 'SIGNATURE',
	TEXTAREA: 'TEXTAREA',
	PHOTO: 'PHOTO',
	DATE: 'DATE',
	TIME: 'TIME',
	DROPDOWN: 'DROPDOWN',
	CHECKBOX: 'CHECKBOX',
}
