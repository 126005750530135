// ionic colors:
export const danger = '#f04141'
export const dark = '#222428'
export const medium = '#989aa2'
export const primary = '#3880ff'
export const secondary = '#3dc2ff'
// custom colors:
export const black = '#000'
export const lightBlue = '#00dff9'
export const llmBlue = '#0061b9'
export const llmError = '#d8534f'
export const llmInfo = '#2fa0ed'
export const llmPrimary = '#f16622'
export const llmSecondary = '#fea000'
export const llmSuccess = '#19bc9b'
export const llmWarning = '#f7981c'
export const silver = '#c0c0c0'
export const white = '#fff'
